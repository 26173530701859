import React from 'react';
import {css} from 'aphrodite/no-important';
import styles from './styles';

interface Props {
    children?: React.ReactNode;
}

const StyledHeading: React.FunctionComponent<Props> = ({children}) => {
    return <h4 className={css(styles.heading)}>
        {children}
    </h4>;
};

export default StyledHeading;
