import React, {ReactElement} from 'react';
import AgGrid from '../../../AgGrid';
import {Props} from './Props';
import {DateCellRenderer} from '../../../AgGrid/components/DateCellRenderer';
import {handleRowClick} from '../TableService';
import {SituationFeature} from '../../../../interfaces/SituationFeature';

class OverviewAllRoadWork extends React.Component<Props> {

    render(): ReactElement {
        const {data, showRecordIds, suppressRowClickSelection} = this.props;

        return <AgGrid<SituationFeature>
            columns={[
                {
                    valueGetter: item => item.data.properties.id,
                    headerName: 'ID',
                    hide: !showRecordIds
                },
                {
                    valueGetter: item => item.data.properties.dataType,
                    headerName: 'Data Type'
                },
                {
                    valueGetter: item => item.data.properties.source,
                    headerName: 'Source'
                },
                {
                    valueGetter: item => item.data.properties.subType,
                    headerName: 'Subtype'
                },
                {
                    cellRenderer: DateCellRenderer,
                    valueGetter: item => item.data.properties.versionTime,
                    headerName: 'Versie tijd'
                },
                {
                    valueGetter: item => item.data.properties.cause,
                    headerName: 'Reden'
                },
                {
                    valueGetter: item => item.data.properties.comment,
                    headerName: 'Comment'
                },
                {
                    cellRenderer: DateCellRenderer,
                    valueGetter: item => item.data.properties.overallStartTime,
                    headerName: 'Start tijd',
                    sort: 'desc'
                },
                {
                    cellRenderer: DateCellRenderer,
                    valueGetter: item => item.data.properties.overallEndTime,
                    headerName: 'Eind tijd'
                },
                {
                    valueGetter: item => item.data.properties.safetyRelatedMessage,
                    headerName: 'Veiligheidsgerelateerd bericht'
                }
            ]}
            data={data}
            enableColResize={true}
            enableFilter={true}
            enableSorting={true}
            rowSelection={'single'}
            suppressScrollOnNewData={true}
            suppressRowClickSelection={suppressRowClickSelection}
            onRowClicked={(item) => handleRowClick(this.props, item)}
        />;
    }

}

export default OverviewAllRoadWork;
