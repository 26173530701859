import {FunctionComponent, useEffect} from 'react';
import {useAuthenticationContext} from '@ndw/react-keycloak-authentication';
import {useSearchParams} from 'react-router-dom';
import {connect} from 'react-redux';
import {Routes} from '../../routing/routes';
import {Spinner} from '../../components/Spinner';
import styles from './styles';
import {css} from 'aphrodite/no-important';
import {REDIRECT_PARAM} from '../../constants';
import {Dispatch} from 'redux';
import {
    changeCurrentRoute,
    LayoutActionTypes,
} from '../../components/Layout/actions';
import {RouterNavigateOptions} from '@remix-run/router/router';

interface Props {
    onChangeCurrentRoute: (
        route: string,
        options: RouterNavigateOptions,
    ) => void;
}

const LoadingScene: FunctionComponent<Props> = ({onChangeCurrentRoute}) => {
    const {isAuthenticated} = useAuthenticationContext();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (isAuthenticated) {
            const redirect = searchParams.get(REDIRECT_PARAM);
            onChangeCurrentRoute(redirect || Routes.MAP, {replace: true});
        }
    }, [isAuthenticated, onChangeCurrentRoute, searchParams]);

    return (
        <aside className={css(styles.wrapper)}>
            <Spinner />
            <p className={css(styles.loadingMessage)}>
                De NDW Viewer wordt geladen.&hellip;
            </p>
        </aside>
    );
};

const mapDispatchToProps = (dispatch: Dispatch<LayoutActionTypes>): Props => ({
    onChangeCurrentRoute: (route: string, options: RouterNavigateOptions) =>
        dispatch(changeCurrentRoute(route, options)),
});

export default connect(null, mapDispatchToProps)(LoadingScene);
