import React, {ReactElement} from 'react';
import {FilterSettings, Props} from './Props';
import {AgGridReact} from '@ag-grid-community/react';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import {GridApi, GridReadyEvent, SelectionChangedEvent} from '@ag-grid-community/core';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import ThemeContext from '../../contexts/ThemeContext';
import {SetFilterModule} from '@ag-grid-enterprise/set-filter';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {State} from './state';

class AgGrid<T> extends React.Component<Props<T>, State<T>> {

    static contextType = ThemeContext;
    context!: React.ContextType<typeof ThemeContext>;

    api: GridApi | null;

    timeOutId: number | null;

    constructor(props: Props<T>) {
        super(props);

        this.api = null;
        this.timeOutId = null;
        this.state = {
            columns: this.props.columns
        };
    }

    componentDidMount(): void {
        this.timeOutId = window.setTimeout(() => this.rerenderInterval(), 30000);
    }

    componentWillUnmount(): void {
        if (this.timeOutId) {
            window.clearTimeout(this.timeOutId);
            this.timeOutId = null;
        }
    }

    componentDidUpdate(prevProps: Readonly<Props<T>>): void {
        if (this.props.filters && !prevProps.filters) {
            this.setFilters(this.props.filters);
        } else if (this.props.filters && prevProps.filters && this.props.filters.identifier !== prevProps.filters.identifier) {
            this.setFilters(this.props.filters);
        } else if (this.props.suppressRowClickSelection !== prevProps.suppressRowClickSelection && this.props.suppressRowClickSelection) {
            this.api?.deselectAll();
        }
    }

    selectionClickCallback = (event: SelectionChangedEvent): void => {
        const selectedRows = event.api.getSelectedRows();
        const selectedRow: T = selectedRows[0] ? selectedRows[0] : undefined;
        this.props.onRowClicked(selectedRow);
    };

    getProps = (): Readonly<Props<T>> => this.props;

    onGridReady = (event: GridReadyEvent): void => {
        const props: Readonly<Props<T>> = this.props;
        const {filters} = props;
        event.api.sizeColumnsToFit();
        this.api = event.api;
        this.setFilters(filters);
    };

    render(): ReactElement {
        const props = this.getProps();
        const {
            data,
            enableColResize,
            enableFilter,
            enableSorting,
            suppressScrollOnNewData,
            rowSelection,
            getRowStyle,
            getContextMenuItems,
            suppressRowClickSelection
        } = props;

        return <div
            className={css(styles.gridContainer) + ' ' + (this.context.currentColorSchemeIsDark ? 'ag-theme-balham-dark' : 'ag-theme-balham')}>
            <AgGridReact
                columnDefs={this.state.columns as never}
                defaultColDef={{
                    filter: enableFilter,
                    resizable: enableColResize,
                    sortable: enableSorting
                }}
                rowSelection={rowSelection}
                suppressScrollOnNewData={suppressScrollOnNewData || true}
                rowData={data}
                onSelectionChanged={this.selectionClickCallback}
                onGridReady={this.onGridReady}
                getRowStyle={getRowStyle}
                getRowId={({data}) => data.id}
                onRowDataUpdated={this.onDataChanged}
                suppressRowClickSelection={suppressRowClickSelection}
                modules={[
                    ClientSideRowModelModule,
                    SetFilterModule,
                    MenuModule
                ]}
                getContextMenuItems={getContextMenuItems}
            />
        </div>;
    }

    private onDataChanged = () => {
        if (!this.api) {
            return;
        }
        if (!this.props.autoRedrawTableColumns) {
            return;
        }

        this.api.refreshCells({
            columns: this.getColumnFieldsWithCustomValue()
        });
    };

    private setFilters = (filters: FilterSettings<T> | undefined) => {
        if (!this.api) {
            return;
        }

        this.api.setFilterModel(null);

        if (filters) {
            filters.filters.forEach((item) => {
                if (!this.api) {
                    return;
                }
                const filterInstance = this.api.getFilterInstance(item.field as string);
                if (!filterInstance) {
                    return;
                }
                filterInstance.setModel(item.value);
            });
        }

        this.api.onFilterChanged();
    };

    private rerenderInterval = () => {
        if (!this.props.autoRedrawTableColumns) {
            return;
        }
        if (!this.api) {
            return;
        }

        this.api.redrawRows();

        this.timeOutId = window.setTimeout(() => this.rerenderInterval(), 30000);
    };

    private getColumnFieldsWithCustomValue(): string[] {
        const columnFields: string[] = [];

        this.props.columns.forEach((column) => {
            if (column.field && (column.cellRenderer || column.valueGetter)) {
                columnFields.push(column.field as string);
            }
        });

        return columnFields;
    }
}

export default AgGrid;
