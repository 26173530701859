import React, {ReactElement} from 'react';
import {Props} from './Props';
import parse from 'html-react-parser';
import {State} from './State';
import {css} from 'aphrodite/no-important';
import styles from './styles';

class SearchItem extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            formattedTitle: null
        };
    }

    componentDidMount(): void {
        this.parseDisplayTitle(
            typeof this.props.searchFeature.displayValue === 'number' ?
                this.props.searchFeature.displayValue.toString(10) : this.props.searchFeature.displayValue
        );
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (this.props.searchFeature.displayValue !== prevProps.searchFeature.displayValue) {
            this.parseDisplayTitle(
                typeof this.props.searchFeature.displayValue === 'number' ?
                    this.props.searchFeature.displayValue.toString(10) : this.props.searchFeature.displayValue
            );
        }
    }

    render(): ReactElement {
        const {searchFeature} = this.props;
        const {formattedTitle} = this.state;

        const displayValue =
            formattedTitle ||
            (typeof searchFeature.displayValue === 'number'
                ? searchFeature.displayValue.toString(10)
                : searchFeature.displayValue);

        return <p
            className={css(styles.item)}
            onClick={() => this.props.clickedOnItem(searchFeature)}
        >
            {parse(displayValue)}
        </p>;
    }

    private parseDisplayTitle = (title: string): void => {
        const {currentSearchValue} = this.props;

        const displayValue = title.replace(new RegExp('(^|)(' + currentSearchValue + ')(|$)', 'ig'), '$1<b>$2</b>$3');

        this.setState({
            formattedTitle: displayValue
        });
    };
}

export default SearchItem;
