import {
    MapSceneTrafficInformationReducerActionTypes,
    mapSceneTrafficInformationSortingDirection,
    mapSceneTrafficInformationSortingValue
} from '../actions/reducers/trafficInformation';
import {getType} from 'typesafe-actions';
import {Reducer} from 'redux';
import {getBooleanValueFromUrl} from '../../../services/UrlService';
import {SortingDirection, SortingValue} from '../../../interfaces/TrafficInformation';

export interface MapSceneTrafficInformationReducerState {
    sortTrafficMessagesByValue: SortingValue;
    updateTrafficMessagesSortingDirection: SortingDirection;
}

const initialState: MapSceneTrafficInformationReducerState = {
    sortTrafficMessagesByValue:
        getBooleanValueFromUrl('sortTrafficMessagesByRoadName', true) ?
            SortingValue.byRoadName : SortingValue.byCreationDate,
    updateTrafficMessagesSortingDirection:
        getBooleanValueFromUrl('sortTrafficMessagesInAscendingOrder', true) ?
            SortingDirection.ascending : SortingDirection.descending
};

const trafficInformationReducer: Reducer<MapSceneTrafficInformationReducerState, MapSceneTrafficInformationReducerActionTypes> =
    (state: MapSceneTrafficInformationReducerState = initialState, action: MapSceneTrafficInformationReducerActionTypes)
        : MapSceneTrafficInformationReducerState => {
        switch (action.type) {
            case getType(mapSceneTrafficInformationSortingValue):
                return {
                    ...state,
                    sortTrafficMessagesByValue: action.payload.isSortByValue
                };
            case getType(mapSceneTrafficInformationSortingDirection):
                return {
                    ...state,
                    updateTrafficMessagesSortingDirection: action.payload.isSortInDirection
                };
            default:
                return state;
        }
    };

export default trafficInformationReducer;
