import {isRouteErrorResponse, useRouteError} from 'react-router-dom';
import {FunctionComponent, useEffect, useState} from 'react';
import {captureException} from '@sentry/react';
import {css} from 'aphrodite';
import styles from './styles';
import StyledButton from '../StyledButton';

const RootErrorBoundary: FunctionComponent = () => {
    const error = useRouteError();
    const [id, setId] = useState<string>();

    useEffect(() => {
        setId(captureException(error));
    }, [error]);

    let message = undefined;
    if (isRouteErrorResponse(error)) {
        message = error.data.message;
    } else if (error instanceof Error) {
        message = error.message;
    } else if (typeof error === 'string') {
        message = error;
    }

    // eslint-disable-next-line no-console
    console.error(error);

    return (
        <section className={css(styles.wrapper)}>
            <div className={css(styles.content)}>
                <h1>Er is een onverwachte fout opgetreden</h1>
                <p>
                    Er ging iets mis bij het laden van de pagina. Deze fout
                    is automatisch gemeld bij NDW.
                </p>
                <p>Herlaad de pagina om het opnieuw te proberen.</p>
                <StyledButton
                    styles={[styles.button]}
                    title="Herlaad pagina"
                    onPress={() => {
                        window.location.reload();
                    }}
                />
                {message && (
                    <details className={css(styles.details)}>
                        <p>ID: {id}</p>
                        <p>{message}</p>
                    </details>
                )}
            </div>
        </section>
    );
};

export default RootErrorBoundary;
