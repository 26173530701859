import './sentry';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom/client';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css';
import 'react-toastify/dist/ReactToastify.min.css';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-balham.css';
import '@ag-grid-community/core/dist/styles/ag-theme-balham-dark.css';
import '@szhsin/react-menu/dist/index.css';
import {Provider} from 'react-redux';
import mapboxGL from 'mapbox-gl';
import {LicenseManager} from '@ag-grid-enterprise/core';
import {store} from './store';
import Chartkick from 'react-chartkick';
import 'chartkick/chart.js';
import PiwikPro from '@piwikpro/react-piwik-pro';
import {ANALYTICS_ENABLED} from './applicationContext';
import {chartColors} from './styles';
import {RouterProvider} from 'react-router-dom';
import {AuthenticationContextProvider} from '@ndw/react-keycloak-authentication';
import {router} from './routing/router';

Chartkick.options = {
    colors: chartColors,
    empty: 'Geen data'
};


// Set the Mapbox access token and ag-grid license key
const {REACT_APP_MAPBOX_ACCESS_TOKEN, REACT_APP_AG_GRID_LICENSE} = process.env;
mapboxGL.accessToken = REACT_APP_MAPBOX_ACCESS_TOKEN as string;
LicenseManager.setLicenseKey(REACT_APP_AG_GRID_LICENSE as string);

if (ANALYTICS_ENABLED) {
    PiwikPro.initialize('3c7b1ac9-3ea8-4d74-8117-6d8d8c158fa5', 'https://statistiek.rijksoverheid.nl');
}


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


root.render(
    <Provider store={store}>
        <AuthenticationContextProvider>
            <RouterProvider router={router} future={{v7_startTransition: true}} />
        </AuthenticationContextProvider>
    </Provider>
);
