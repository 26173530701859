export const chartColors: ReadonlyArray<string> = [
    '#ac183d',
    '#3cb44b',
    '#ffe119',
    '#0082c8',
    '#f58231',
    '#911eb4',
    '#46f0f0',
    '#f032e6',
    '#d2f53c',
    '#fabebe',
    '#008080',
    '#e6beff',
    '#aa6e28',
    '#800000',
    '#aaffc3',
    '#808000',
    '#ffd8b1',
    '#75b424',
    '#000080',
    '#ff0000',
    '#808080',
    '#000000',
    '#fffac8',
    '#597e4b',
    '#6ce687',
    '#31ff00',
    '#5c8ae7',
    '#f55fad',
    '#10483a',
    '#420941',
    '#455315',
    '#ac183d',
    '#3cb44b',
    '#ffe119',
    '#0082c8',
    '#f58231',
    '#911eb4',
    '#46f0f0',
    '#f032e6',
    '#d2f53c',
    '#fabebe'
];

export const NAV_BAR_HEIGHT = 40;
export const NAV_BAR_COLORLINE_HEIGHT = 8;
const COLORLINE_COUNT = 2;
export const NAV_BAR_COLORLINES_TOTAL_HEIGHT = COLORLINE_COUNT * NAV_BAR_COLORLINE_HEIGHT;

export interface AcceptanceStyle {
    primary: string;
    secondary: string;
    tertiary: string;
}

export const ACCEPTANCE_STYLE: AcceptanceStyle = {
    primary: '#FFF59D',
    secondary: '#FFFFCF',
    tertiary: '#CBC26D'
};

export const ACCEPTANCE_STYLE_DARK: AcceptanceStyle = {
    primary: '#558B2F',
    secondary: '#85BB5C',
    tertiary: '#255D00'
};
