import React, {ChangeEvent, ReactElement} from 'react';
import {Props} from './Props';
import MessageAgeInMinutes from '../../../../../../components/MessageAgeInMinutes';
import StyledBoolean from '../../../../../../components/StyledBoolean';
import StyledHeading from '../../../../../../components/StyledHeading';
import {css} from 'aphrodite';
import styles from './styles';
import {convertWazeTypeAndSubtypeToTranslatedEventType, translateFrontOfficeEventStatus} from '../../../../../../services/Utilities';
import StyledSelect from '../../../../../../components/StyledSelect';
import TimeFormatted from '../../../../../../components/TimeFormatted';
import StyledInput from '../../../../../../components/StyledInput';
import StyledButton from '../../../../../../components/StyledButton';
import {WAZE_ALERT_ITEM_SUB_TYPES} from '../../../../../../interfaces/WazeAlertItemStatus';
import {FrontOfficeEventStatus, wazeTypesOptions} from '../../../../../../constants';
import {SavingStatus} from '../../../../../../interfaces/ActiveDialog';

class DialogWazeAlertIncident extends React.Component<Props> {

    componentDidMount(): void {
        const {currentProperties, openWazeAlertDialog} = this.props;
        openWazeAlertDialog(currentProperties.id as string);
    }

    componentWillUnmount(): void {
        const {currentProperties, closeWazeAlertDialog} = this.props;
        closeWazeAlertDialog(currentProperties.id as string);
    }

    changeStatus = (e: ChangeEvent<HTMLSelectElement>): void => {
        const {currentProperties, changeWazeAlertData} = this.props;

        changeWazeAlertData(
            currentProperties.id,
            `${currentProperties.street} ${currentProperties.city}`,
            e.target.value as FrontOfficeEventStatus,
            undefined,
            undefined,
            undefined
        );
    };

    changeType = (e: ChangeEvent<HTMLSelectElement>): void => {
        const {currentProperties, changeWazeAlertData} = this.props;

        changeWazeAlertData(
            currentProperties.id,
            `${currentProperties.street} ${currentProperties.city}`,
            undefined,
            undefined,
            'ACCIDENT',
            e.target.value as WAZE_ALERT_ITEM_SUB_TYPES
        );
    };

    changeHectometrePost = (value: string): void => {
        const {currentProperties, changeWazeAlertData} = this.props;

        changeWazeAlertData(
            currentProperties.id,
            `${currentProperties.street} ${currentProperties.city}`,
            undefined,
            value,
            undefined,
            undefined
        );
    };

    updateWazeStatus = (): void => {
        const {currentProperties, saveWazeAlertData, status, hectometrePost, subType} = this.props;

        saveWazeAlertData(
            currentProperties.id,
            `${currentProperties.street} ${currentProperties.city}`,
            status || undefined,
            hectometrePost || currentProperties.hectometrePost || undefined,
            undefined,
            subType || currentProperties.subType || undefined
        );
    };

    render(): ReactElement {
        const {
            currentProperties,
            hectometrePost,
            isOpen,
            status,
            dataHasBeenLoaded,
            dataLoadingHasFailed,
            timeRemainingBeforeAutomaticClosure,
            subType,
            showRecordIds
        } = this.props;

        let minutes = 0;
        let seconds = 0;

        if (timeRemainingBeforeAutomaticClosure) {
            minutes = Math.floor(timeRemainingBeforeAutomaticClosure / 60) % 60;
            seconds = timeRemainingBeforeAutomaticClosure - (minutes * 60);
        }

        let hectometrePostFallback: string = hectometrePost!;
        if (typeof hectometrePost === 'undefined' || !hectometrePost) {
            hectometrePostFallback = currentProperties.hectometrePost;
        }

        return <>
            <table>
                <tbody>
                    {showRecordIds && <tr>
                        <th>ID</th>
                        <td>{currentProperties.id}</td>
                    </tr>}
                    <tr>
                        <th>Type</th>
                        <td>{convertWazeTypeAndSubtypeToTranslatedEventType(currentProperties.type, currentProperties.subType)}</td>
                    </tr>
                    <tr>
                        <th>Locatie (Waze)</th>
                        <td>{currentProperties.street}</td>
                    </tr>
                    <tr>
                        <th>Locatie (NDW)</th>
                        <td>{currentProperties.hectometrePostRoad} {currentProperties.hectometrePost}</td>
                    </tr>
                    <tr>
                        <th>Locatie (WVL)</th>
                        <td>{currentProperties.location}</td>
                    </tr>
                    <tr>
                        <th>Starttijd</th>
                        <td><TimeFormatted dateTime={currentProperties.publicationTime}/></td>
                    </tr>
                    <tr>
                        <th>Duur incident</th>
                        <td><MessageAgeInMinutes time={currentProperties.publicationTime}/></td>
                    </tr>
                    <tr>
                        <th>Aantal bevestigingen</th>
                        <td>{currentProperties.thumbsUp}</td>
                    </tr>
                    <tr>
                        <th>Laatste bevestiging</th>
                        <td>
                            <MessageAgeInMinutes
                                time={currentProperties.lastConfirmedTime}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Al bekend bij NDW?</th>
                        <td><StyledBoolean value={currentProperties.ndw}/></td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <td>{status && translateFrontOfficeEventStatus(status)}{!status && 'Er is nog geen status opgeslagen'}</td>
                    </tr>
                </tbody>
            </table>

            {!dataLoadingHasFailed && <>
                <StyledHeading>Melding behandelings status</StyledHeading>
                {(dataHasBeenLoaded && status !== null) && <>
                    {(isOpen) && <p className={css(styles.openText)}>Deze melding wordt op dit moment al behandeld door een
                        collega, je kan de status hierdoor niet aanpassen.</p>}

                    {!isOpen && <>
                        <p className={css(styles.closedText)}>Deze melding staat nu aangemeld als open, en is niet
                            aanpasbaar
                            bij een collega. Deze melding word automatisch weer aanpasbaar
                            over <strong>{minutes}:{seconds < 10 ? '0' : ''}{seconds}</strong> minuten.</p>

                        <table>
                            <tbody>
                                <tr>
                                    <td style={{paddingRight: 10}}>
                                        <label><strong>Status</strong></label>
                                        <StyledSelect
                                            disabled={this.statusSelectFieldIsDisabled()}
                                            value={status}
                                            onChange={this.changeStatus}
                                        >
                                            <option value={'NONE'} disabled={true}>Niet beoordeeld</option>
                                            <option value={'NO_ACTION'}>Niets aan de hand</option>
                                            <option value={'SELF_ACTION'}>Zelf laten oplossen</option>
                                            <option value={'KNOW_MESSAGE'}>Reeds bekend</option>
                                            <option value={'START_ACTION'}>IM Opstarten</option>
                                        </StyledSelect>
                                    </td>
                                    <td style={{paddingLeft: 10}}>
                                        <label><strong>Melding type</strong></label>
                                        <StyledSelect
                                            disabled={this.statusSelectFieldIsDisabled()}
                                            value={subType}
                                            onChange={this.changeType}
                                        >
                                            <option value={undefined} disabled={true}>Niet aanpassen</option>
                                            {wazeTypesOptions.map((item) =>
                                                <option key={item.subType} value={item.subType}>{item.name}</option>)}
                                        </StyledSelect>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <br/>

                        <div className={css(styles.inputContainer)}>
                            <div className={css(styles.inputFlex)}>
                                <StyledInput
                                    disabled={this.hectometrePostFieldIsDisabled()}
                                    type={'text'}
                                    value={hectometrePostFallback}
                                    changeInput={(value) => this.changeHectometrePost(value)}
                                    placeholder={'Geen HM informatie beschikbaar'}
                                    maxLength={25}
                                />
                            </div>
                            <div className={css(styles.saveButtonFlex)}>
                                <StyledButton
                                    disabled={!this.saveButtonIsDisabled()}
                                    onPress={() => this.updateWazeStatus()}
                                    title={this.saveButtonContent()}
                                    aria-label={'Sla statuswijziging op'}
                                />
                            </div>
                        </div>

                        {this.renderUnsavedChanges()}
                    </>}
                </>}

                {!dataHasBeenLoaded && <p className={css(styles.loadingDataText)}>Waze melding gegevens worden op dit moment geladen...</p>}
            </>}
        </>;
    }

    private saveButtonContent = (): React.ReactNode => {
        const {savingStatus} = this.props;

        if (savingStatus) {
            switch (savingStatus) {
                case SavingStatus.callingServer:
                    return <>
                        <i className={'fas fa-sync fa-spin'}/> Opslaan...
                    </>;
                case SavingStatus.changesSaved:
                    return <>
                        <i className={'fas fa-check-circle'}/> Opgeslagen
                    </>;
                case SavingStatus.savingFailed:
                    return <>
                        <i className={'fas fa-times-circle'}/> Niet opgeslagen
                    </>;
            }
        }

        return <>
            <i className={'fas fa-save'}/> Opslaan
        </>;
    };

    private saveButtonIsDisabled = (): boolean => {
        const {savingStatus} = this.props;

        return !(savingStatus && savingStatus === SavingStatus.callingServer);
    };

    private hectometrePostFieldIsDisabled = (): boolean => {
        return !this.saveButtonIsDisabled();
    };

    private statusSelectFieldIsDisabled = (): boolean => {
        return !this.saveButtonIsDisabled();
    };

    private renderUnsavedChanges = (): React.ReactNode => {
        const {lastChangeTimestamp, lastSaveTimestamp} = this.props;

        if ((lastChangeTimestamp && !lastSaveTimestamp) || (lastChangeTimestamp !== lastSaveTimestamp)) {
            return <div className={css(styles.unusedText)}>Je hebt je wijzigingen nog niet opgeslagen, doe dit voor je de
                popup sluit.
            </div>;
        }

        return <React.Fragment/>;
    };
}

export default DialogWazeAlertIncident;
