import React, {ReactElement} from 'react';
import {DispatchProps, Props, StateProps} from './Props';
import {
    changeDialogPinStatus,
    closeAllUnpinnedPopups,
    closeHighestPriorityDialog,
    closePopupWithID,
    flyToLocation,
    focusOnDialog,
    mapSceneGeneralToggleMapMovementAvailable,
    mapSceneGeneralUpdateHighlightContextMenu,
    mapSceneGeneralUpdateHighlightFeatures,
    mapSceneNumberOfFeaturesVisibleOnMap,
    storeMapSceneContainerViewPartHeight,
    updateSidebarStatus
} from './actions';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {RootState} from '../../reducer';
import Sidebar from './components/Sidebar';
import {MAP_SCENE} from '../../scenes';
import {viewerApplicationSceneChanged} from '../../actions';
import {MapView} from '../../interfaces/Mapbox';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import {MapFeature} from '../../interfaces/MapFeature';
import Dialogs from './components/Dialogs';
import Mapbox from './components/Mapbox';
import StyledButton from '../../components/StyledButton';
import {
    appendNewTrajectoriesToSummedTrajectoriesPaneList,
    removeTrajectoryFromSelection,
    toggleTrajectorySummedSelection
} from './actions/reducers/summedTrajactoriesGraph';
import {closeGraphDialog, openGraphDialog} from './actions/travelTimeDialog';
import {
    disableMapLayer,
    enableMapLayer,
    hidePreviewPopup,
    showPopupForFeatures,
    showPreviewPopupForFeatures,
    updateMapIsReadyStatus,
    updateMapViewport
} from './actions/mapBoxComponent';
import {mapSceneOpenBridgeGraphDialog} from './actions/bridgeDialog';
import {
    mapSceneSettingsPanelSetNewTravelTimeLineOffset,
    mapSceneSettingsPanelToggleShowRecordId,
    mapSceneSettingsPanelToggleShowTrafficJamIcons,
    mapSceneSettingsPaneSelectedNewTheme
} from './actions/settingsPane';
import {
    changeWazeAlertData,
    closeWazeAlertDialog,
    openWazeAlertDialog,
    saveWazeAlertData
} from './actions/wazeAlertIncidentDialog';
import OverviewTable from './components/OverviewTable';
import {
    mapSceneOverviewTableChangeOverviewTableSource,
    setNewVisibleOverviewAllTable,
    updateOverviewTableHeight
} from './actions/overviewTableComponent';
import {
    selectedNewTrafficCenter,
    setNewDfineFilterStatusOnlyRvmNetwork,
    setNewFdVerifiedTrafficJamsFilterStatus,
    setNewPrefixFilterForLayer,
    setNewWazeAlertFilterStatusSet,
    setNewWazeAlertNdwKnownFilterStatus,
    setSituationTypeFilter,
    storeNewSearchValue
} from './actions/reducers/filterAndSearch';
import {OverviewAllTable, OverviewTableSource} from '../../interfaces/OverviewAllTable';
import {mapSceneUpdateNotificationMessage} from './actions/notificationPane';
import {DATA_SOURCE_STATUS_UPDATE_OUT_OF_SYNC} from '../../interfaces/DataSource';
import {SidebarContentPanes} from './reducers/generalReducer';
import {ControlledMenu, MenuGroup, MenuItem} from '@szhsin/react-menu';
import _ from 'lodash';
import {
    mapSceneTrafficInformationSortingDirection,
    mapSceneTrafficInformationSortingValue
} from './actions/reducers/trafficInformation';
import {SituationFeature} from '../../interfaces/SituationFeature';

class MapScene extends React.Component<Props> {

    pageContainer: HTMLDivElement | null;

    constructor(props: Props) {
        super(props);

        this.pageContainer = null;
    }

    componentDidMount(): void {
        this.props.onSceneChanged(MAP_SCENE);
        document.addEventListener('keydown', this.onKeyPressed);
        window.addEventListener('resize', this.onResizeWindow);
    }

    componentWillUnmount(): void {
        document.removeEventListener('keydown', this.onKeyPressed);
        window.removeEventListener('resize', this.onResizeWindow);
    }

    onKeyPressed = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            const {activeDialogs, onCloseHighestPriorityDialog, onUpdateSidebarStatus} = this.props;
            if (activeDialogs.length > 0) {
                onCloseHighestPriorityDialog();
            } else {
                onUpdateSidebarStatus(null);
            }
        }
    };

    containerRefCallback = (element: HTMLDivElement | null) => {
        setTimeout(() => {
            this.pageContainer = element;
            this.updateMapSceneContainerHeight();
        }, 100);
    };

    handleViewportChange = (viewport: MapView) => {
        this.props.onViewportChange(viewport);
    };

    clickedOnSituationFeature = (geoJsonFeature: SituationFeature) => {
        const {mapLayers, mapMovementAvailable, onEnableMapLayer, onFlyToLocation} = this.props;

        if (!mapMovementAvailable) {
            return;
        }


        const trafficMessagesLayer = mapLayers.trafficMessage;
        if (trafficMessagesLayer && !trafficMessagesLayer.isActive) {
            onEnableMapLayer(trafficMessagesLayer.id);
        }
        switch (geoJsonFeature.geometry.type) {
            case 'Point':
                onFlyToLocation(geoJsonFeature.geometry.coordinates[1],
                    geoJsonFeature.geometry.coordinates[0], 13);
                break;
            case 'LineString':
                onFlyToLocation(geoJsonFeature.geometry.coordinates[0][1],
                    geoJsonFeature.geometry.coordinates[0][0], 13);
                break;
        }
    };

    clickedOnMapLayer = (layerID: string) => {
        const {mapLayers, onDisableMapLayer, onEnableMapLayer} = this.props;

        const mapLayer = mapLayers[layerID];

        if (mapLayer.isActive) {
            onDisableMapLayer(layerID);
        } else {
            onEnableMapLayer(layerID);
        }
    };

    onUserMapClick = (features: MapFeature[], pointX: number, pointY: number) => {
        const {
            sidebarVisibleTab,
            trajectorySummedSelectionEnabled,
            onUpdateSidebarStatus,
            onAppendNewTrajectoriesToSummedTrajectoriesPaneList,
            onShowPopupForFeatures
        } = this.props;

        if (trajectorySummedSelectionEnabled) {
            if (sidebarVisibleTab !== SidebarContentPanes.TRAJECTORY_SUMMED) {
                onUpdateSidebarStatus(SidebarContentPanes.TRAJECTORY_SUMMED);
            }

            onAppendNewTrajectoriesToSummedTrajectoriesPaneList(features);
        } else {
            onUpdateSidebarStatus(null);
            if (features.length > 0) {
                onShowPopupForFeatures(features, pointX, pointY);
            }
        }
    };

    onUserMapRightClick = (features: MapFeature[], pointX: number, pointY: number) => {
        const {onHighlightContextMenu, onUpdateHighlightFeatures} = this.props;

        if (features.length === 0) {
            onUpdateHighlightFeatures(null);
            return;
        }

        onHighlightContextMenu({
            features: _.groupBy(features, 'source'),
            x: pointX,
            y: pointY
        });
    };

    mapHasFinishedLoading = () => {
        this.props.onUpdateMapIsReadyStatus(true);
    };

    getHeightOverviewTablePart = (): number => {
        const {showTables, viewPartHeight} = this.props;

        if (!showTables) {
            return 0;
        }

        if (viewPartHeight.overviewTables) {
            return viewPartHeight.overviewTables;
        }

        return 200;
    };

    goToClickedTableFeature = (latitude: number, longitude: number) => {
        if (!this.props.mapMovementAvailable) {
            return;
        }

        this.props.onFlyToLocation(latitude, longitude, 13);
    };

    changeCurrentOverviewTable = (newTable: OverviewAllTable | null, currentTable: OverviewAllTable | null) => {
        const {mapLayers, currentVisibleTableSource, onDisableMapLayer, onEnableMapLayer, onSetNewVisibleOverviewAllTable} = this.props;

        let toDisableMapLayers: string[] | null = null;
        let toEnableMapLayers: string[] | null = null;

        if (newTable && !currentTable) {
            const newTableMapLayerId = newTable.sources[0]?.mapLayers;

            if (newTableMapLayerId) {
                toEnableMapLayers = newTableMapLayerId;
            }
        } else if (newTable && currentTable) {
            const newTableMapLayerId = newTable.sources[0]?.mapLayers;

            if (newTableMapLayerId) {
                toEnableMapLayers = newTableMapLayerId;
            }

            if (currentVisibleTableSource && currentVisibleTableSource.mapLayers) {
                toDisableMapLayers = currentVisibleTableSource.mapLayers;
            }
        } else if (!newTable && currentTable) {
            const currenTableMapLayerId = currentVisibleTableSource?.mapLayers;

            if (currenTableMapLayerId) {
                toDisableMapLayers = currenTableMapLayerId;
            }
        }
        toDisableMapLayers?.forEach(toDisableMapLayer => {
            if (mapLayers[toDisableMapLayer] && mapLayers[toDisableMapLayer].isActive) {
                onDisableMapLayer(toDisableMapLayer);
            }
        });


        toEnableMapLayers?.forEach(toEnableMapLayer => {
            if (mapLayers[toEnableMapLayer] && !mapLayers[toEnableMapLayer].isActive) {
                onEnableMapLayer(toEnableMapLayer);
            }
        });

        onSetNewVisibleOverviewAllTable(newTable);
    };

    changeCurrentOverviewTableSource = (overviewTableSource: OverviewTableSource): void => {
        const {mapLayers, currentVisibleTableSource, onDisableMapLayer, onEnableMapLayer, onOverviewTableChangeOverviewTableSource} = this.props;

        if (currentVisibleTableSource && currentVisibleTableSource.mapLayers) {
            currentVisibleTableSource.mapLayers.forEach(mapLayer => {
                if (mapLayers[mapLayer] &&
                    mapLayers[mapLayer].isActive &&
                    currentVisibleTableSource.sourceType !== overviewTableSource.sourceType
                ) {
                    onDisableMapLayer(mapLayer);
                }

                if (overviewTableSource.mapLayers && mapLayers[mapLayer] && !mapLayers[mapLayer].isActive) {
                    onEnableMapLayer(mapLayer);
                }
            });
        }

        onOverviewTableChangeOverviewTableSource(overviewTableSource);
    };

    render(): ReactElement {
        const {
            activeDialogs,
            currentSearchValue,
            currentTrafficCenter,
            currentVisibleTable,
            currentVisibleTableSource,
            highlightFeatures,
            legendData,
            mapFilter,
            mapFlyToLocation,
            mapLayers,
            mapSettings,
            dataSources,
            mapMovementAvailable,
            numberOfUnpinnedDialogs,
            prefixes,
            showTables,
            searchResults,
            sidebarVisibleTab,
            theme,
            trafficCenters,
            trafficMessages,
            sortTrafficMessagesByValue,
            updateTrafficMessagesSortingDirection,
            trajectorySummedData,
            trajectorySummedSelection,
            trajectorySummedSelectionEnabled,
            viewport,
            viewPartHeight,
            showRecordIds,
            showTrafficJamIcons,
            highlightContextMenu,
            onUpdateSidebarStatus,
            onUserHoverOverFeature,
            onUserExistsHoverOverFeature,
            numberOfFeaturesVisibleOnMap,
            changeCurrentTheme,
            onFlyToLocation,
            onRemoveTrajectoryFromSelection,
            onToggleTrajectorySummedSelection,
            onSetNewTravelTimeLineOffset,
            onSetNewWazeAlertFilterStatusSet,
            onSetNewWazeAlertNdwKnownFilterStatus,
            onSetNewDfineFilterStatusOnlyRvmNetwork,
            onSelectedNewTrafficCenter,
            onStoreNewSearchValue,
            onUpdateNotificationMessage,
            onToggleShowRecordIds,
            onSetNewPrefixFilter,
            onToggleMapMovementAvailable,
            onUpdateSortTrafficMessagesByValue,
            onUpdateTrafficMessagesSortingDirection,
            onToggleShowTrafficJamIcons,
            onSetNewFdVerifiedTrafficJamsFilterStatus,
            onSetSituationTypeFilter,
            onChangeDialogPinStatus,
            onClosePopupWithID,
            onShowPopupForFeatures,
            onCloseGraphDialog,
            onFocusOnDialog,
            onOpenBridgeGraphDialog,
            onOpenGraphDialog,
            onCloseWazeAlertDialog,
            onOpenWazeAlertDialog,
            onChangeWazeAlertData,
            onSaveWazeAlertData,
            onCloseAllUnpinnedPopups,
            onUpdateOverviewTableHeight,
            onUpdateHighlightFeatures,
        } = this.props;
        return <div
            className={css(styles.mapSceneContainer)}
            ref={this.containerRefCallback}
        >

            <div
                style={{height: viewPartHeight.mapHeight ? viewPartHeight.mapHeight : '100%'}}
                className={css(styles.containerMapViewPart)}
            >
                <Mapbox
                    mapFilter={mapFilter}
                    mapFlyToLocation={mapFlyToLocation}
                    mapboxStyleUrl={theme.mapboxStyleUrl}
                    mapLayers={mapLayers}
                    mapSettings={mapSettings}
                    dataSources={dataSources}
                    showTrafficJamIcons={showTrafficJamIcons}
                    searchResults={searchResults}
                    sidebarVisibleTab={sidebarVisibleTab}
                    viewport={viewport}
                    highlightFeatures={highlightFeatures}
                    onUpdateSidebarStatus={onUpdateSidebarStatus}
                    mapHasFinishedLoading={this.mapHasFinishedLoading}
                    handleViewportChange={this.handleViewportChange}
                    onUserMapClick={this.onUserMapClick}
                    onUserMapRightClick={this.onUserMapRightClick}
                    onUserHoverOverFeature={onUserHoverOverFeature}
                    onUserExistsHoverOverFeature={onUserExistsHoverOverFeature}
                    numberOfFeaturesVisibleOnMap={numberOfFeaturesVisibleOnMap}
                />

                <Sidebar
                    currentSearchValue={currentSearchValue}
                    currentTrafficCenter={currentTrafficCenter}
                    currentThemeID={theme.index}
                    currentVisibleTable={currentVisibleTable}
                    legendData={legendData}
                    mapLayers={mapLayers}
                    mapFilter={mapFilter}
                    mapSettings={mapSettings}
                    mapSources={dataSources}
                    mapMovementAvailable={mapMovementAvailable}
                    prefixes={prefixes}
                    searchResults={searchResults}
                    sidebarVisibleTab={sidebarVisibleTab}
                    showOverviewTable={showTables}
                    trafficCenters={trafficCenters}
                    trafficMessages={trafficMessages}
                    sortByValue={sortTrafficMessagesByValue}
                    sortInDirection={updateTrafficMessagesSortingDirection}
                    trajectorySummedData={trajectorySummedData}
                    trajectorySummedSelection={trajectorySummedSelection}
                    trajectorySummedSelectionEnabled={trajectorySummedSelectionEnabled}
                    showRecordIds={showRecordIds}
                    showTrafficJamIcons={showTrafficJamIcons}
                    changeCurrentTheme={changeCurrentTheme}
                    clickedOnMapLayer={this.clickedOnMapLayer}
                    clickedOnSituationFeature={this.clickedOnSituationFeature}
                    onFlyToLocation={onFlyToLocation}
                    onUpdateSidebarStatus={onUpdateSidebarStatus}
                    removeTrajectoryFromSelection={onRemoveTrajectoryFromSelection}
                    toggleTrajectorySummedSelectionEnabled={onToggleTrajectorySummedSelection}
                    setNewVisibleOverviewAllTable={this.changeCurrentOverviewTable}
                    setNewTravelTimeLineOffset={onSetNewTravelTimeLineOffset}
                    setNewWazeAlertFilterStatusSet={onSetNewWazeAlertFilterStatusSet}
                    setNewWazeAlertNdwKnownFilterStatus={onSetNewWazeAlertNdwKnownFilterStatus}
                    setNewDfineFilterStatusOnlyRvmNetwork={onSetNewDfineFilterStatusOnlyRvmNetwork}
                    selectedNewTrafficCenter={onSelectedNewTrafficCenter}
                    changeSearchFieldValue={onStoreNewSearchValue}
                    updateNotificationMessage={onUpdateNotificationMessage}
                    checkPermissionForFeature={this.checkAccessForFeature}
                    onToggleShowRecordIds={onToggleShowRecordIds}
                    onSetNewPrefixFilter={onSetNewPrefixFilter}
                    onToggleMapMovementAvailable={onToggleMapMovementAvailable}
                    onUpdateSortTrafficMessagesByValue={onUpdateSortTrafficMessagesByValue}
                    onUpdateTrafficMessagesSortingDirection={onUpdateTrafficMessagesSortingDirection}
                    onToggleShowTrafficJamIcons={onToggleShowTrafficJamIcons}
                    setNewFdVerifiedTrafficJamsFilterStatus={onSetNewFdVerifiedTrafficJamsFilterStatus}
                    setSituationTypeFilter={onSetSituationTypeFilter}
                />

                <Dialogs
                    activeDialogs={activeDialogs}
                    mapLayers={mapLayers}
                    showRecordIds={showRecordIds}
                    changeDialogPinStatus={onChangeDialogPinStatus}
                    requestCloseDialog={onClosePopupWithID}
                    clickedFeatures={onShowPopupForFeatures}
                    closeDialog={onCloseGraphDialog}
                    focusOnDialog={onFocusOnDialog}
                    openBridgeGraphDialog={onOpenBridgeGraphDialog}
                    openGraphDialog={onOpenGraphDialog}
                    closeWazeAlertDialog={onCloseWazeAlertDialog}
                    openWazeAlertDialog={onOpenWazeAlertDialog}
                    changeWazeAlertData={onChangeWazeAlertData}
                    saveWazeAlertData={(id, name, status, hectometrePost, type, subType) =>
                        onSaveWazeAlertData(id, name, status, hectometrePost, type, subType)}
                />

                {numberOfUnpinnedDialogs > 0 &&
                    <div className={css(styles.closeAllDialogsContainer)}>
                        <StyledButton
                            onPress={() => onCloseAllUnpinnedPopups()}
                            title={'Sluit alle pop-ups'}
                        />
                    </div>}

                {this.hasOutdatedSources() && <div className={css(styles.dataIsOutdated)}>
                    <i className="fas fa-sync"/>&nbsp;&nbsp;Kaartgegevens lopen achter
                </div>}

            </div>

            <div
                style={{height: this.getHeightOverviewTablePart()}}
            >
                <OverviewTable
                    currentVisibleTable={currentVisibleTable}
                    currentVisibleTableSource={currentVisibleTableSource}
                    currentTrafficCenter={currentTrafficCenter}
                    mapFilter={mapFilter}
                    mapMovementAvailable={mapMovementAvailable}
                    overviewTablesVisible={showTables}
                    overviewAllData={dataSources}
                    showRecordIds={showRecordIds}
                    updateOverviewTableHeight={onUpdateOverviewTableHeight}
                    clickedOnTableRow={this.goToClickedTableFeature}
                    theme={theme}
                    onSaveWazeAlertData={(id, name, status, type, subType) =>
                        onSaveWazeAlertData(id, name, status, undefined, type, subType)}
                    onOverviewTableChangeOverviewTableSource={this.changeCurrentOverviewTableSource}
                />
            </div>

            <ControlledMenu
                anchorPoint={{
                    x: highlightContextMenu?.x || -1,
                    y: highlightContextMenu?.y || -1
                }} state={highlightContextMenu ? 'open' : 'closed'}>
                {highlightContextMenu && Object.keys(highlightContextMenu.features).map((mapSource) =>
                    <MenuGroup takeOverflow key={mapSource}>
                        <MenuItem>{mapSource}</MenuItem>
                        {highlightContextMenu.features[mapSource].map((feature) => <MenuItem
                            key={feature.id}
                            onClick={() => onUpdateHighlightFeatures([feature])}>
                            - {feature.id}
                        </MenuItem>)}
                    </MenuGroup>)}
                <MenuItem
                    onClick={() => onUpdateHighlightFeatures(this.getAllFeaturesFromHighLight())}>Alles</MenuItem>

            </ControlledMenu>
        </div>;
    }

    private getAllFeaturesFromHighLight = (): MapFeature[] => {
        let mapClickedFeatures: MapFeature[] = [];
        Object.values(this.props.highlightContextMenu?.features || []).forEach((features) => mapClickedFeatures = [
            ...features
        ]);

        return mapClickedFeatures;
    };

    /**
     * When window has resized update the height
     */
    private onResizeWindow = () => {
        this.updateMapSceneContainerHeight();
    };

    /**
     * Get the current page container height
     */
    private updateMapSceneContainerHeight = () => {
        if (!this.pageContainer) {
            return;
        }

        this.props.onStoreMapSceneContainerViewPartHeight(this.pageContainer.getBoundingClientRect().height);
    };

    private checkAccessForFeature = (permission: string): boolean => {
        const {access} = this.props;

        if (access.development) {
            return true;
        }

        return access.permissions.indexOf(permission) > -1;
    };

    private hasOutdatedSources = (): boolean => {
        let hasOutdatedSources = false;
        const {dataSources} = this.props;

        Object.values(dataSources).forEach((dataSource) => {
            if (dataSource.status === DATA_SOURCE_STATUS_UPDATE_OUT_OF_SYNC) {
                hasOutdatedSources = true;
            }
        });

        return hasOutdatedSources;
    };
}

const mapStateToProps = (state: RootState): StateProps => ({
    access: state.userSettings.access,
    activeDialogs: state.mapScene.activeDialogs.activeDialogs,
    currentSearchValue: state.mapScene.filterAndSearch.currentSearchValue,
    currentTrafficCenter: state.userSettings.currentTrafficCenter,
    currentVisibleTable: state.mapScene.general.visibleTable,
    currentVisibleTableSource: state.mapScene.general.visibleTableSource,
    highlightContextMenu: state.mapScene.general.highlightContextMenu,
    highlightFeatures: state.mapScene.general.highlightFeatures,
    legendData: state.mapScene.general.legendData,
    mapFilter: state.mapScene.filterAndSearch.mapFilters,
    mapFlyToLocation: state.mapScene.general.flyToLocation,
    mapLayers: state.mapScene.mapboxLayers.layers,
    mapSettings: state.mapScene.general.mapSettings,
    dataSources: state.mapScene.data.sources,
    mapMovementAvailable: state.mapScene.general.mapMovementAvailable,
    numberOfUnpinnedDialogs: state.mapScene.activeDialogs.numberOfUnpinnedDialogs,
    prefixes: state.mapScene.mapboxLayers.prefixes,
    searchResults: state.mapScene.filterAndSearch.searchResults,
    showRecordIds: state.userSettings.showRecordId,
    showTrafficJamIcons: state.userSettings.showTrafficJamIcons,
    showTables: state.mapScene.general.showTables,
    sidebarVisibleTab: state.mapScene.general.sidebarVisibleTab,
    theme: state.userSettings.theme,
    trafficCenters: state.trafficCenter.trafficCenters,
    sortTrafficMessagesByValue: state.mapScene.trafficInformation.sortTrafficMessagesByValue,
    updateTrafficMessagesSortingDirection: state.mapScene.trafficInformation.updateTrafficMessagesSortingDirection,
    trafficMessages: state.mapScene.data.sources,
    trajectorySummedData: state.mapScene.summedTrajectoriesGraph.trajectorySummedData,
    trajectorySummedSelection: state.mapScene.summedTrajectoriesGraph.trajectorySummedSelection,
    trajectorySummedSelectionEnabled: state.mapScene.summedTrajectoriesGraph.trajectorySummedSelectionEnabled,
    viewPartHeight: state.mapScene.general.viewPartHeight,
    viewport: state.mapScene.general.viewport
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    changeCurrentTheme: (newThemeID) => dispatch(mapSceneSettingsPaneSelectedNewTheme(newThemeID)),
    onAppendNewTrajectoriesToSummedTrajectoriesPaneList: (clickedFeatures) =>
        dispatch(appendNewTrajectoriesToSummedTrajectoriesPaneList(clickedFeatures)),
    onChangeDialogPinStatus: (index, newStatus) => dispatch(changeDialogPinStatus(index, newStatus)),
    onChangeWazeAlertData: (id, name, status, hectometrePost, type, subType) =>
        dispatch(changeWazeAlertData(id, name, status, hectometrePost, type, subType)),
    onCloseAllUnpinnedPopups: () => dispatch(closeAllUnpinnedPopups()),
    onCloseGraphDialog: (mapSourceID, featureID) => dispatch(closeGraphDialog(mapSourceID, featureID)),
    onCloseHighestPriorityDialog: () => dispatch(closeHighestPriorityDialog()),
    onClosePopupWithID: (id) => dispatch(closePopupWithID(id)),
    onCloseWazeAlertDialog: (id) => dispatch(closeWazeAlertDialog(id)),
    onDisableMapLayer: (layerID) => dispatch(disableMapLayer(layerID)),
    onEnableMapLayer: (layerID) => dispatch(enableMapLayer(layerID)),
    onFlyToLocation: (latitude, longitude, zoom) => dispatch(flyToLocation(latitude, longitude, zoom)),
    onFocusOnDialog: (index) => dispatch(focusOnDialog(index)),
    onOpenBridgeGraphDialog: (risIndex, vild) => dispatch(mapSceneOpenBridgeGraphDialog(risIndex, vild)),
    onOpenGraphDialog: (mapSourceID, featureID, locationID, dialog, timestamp) =>
        dispatch(openGraphDialog(mapSourceID, featureID, locationID, dialog, timestamp)),
    onOpenWazeAlertDialog: (id) => dispatch(openWazeAlertDialog(id)),
    onOverviewTableChangeOverviewTableSource: (overviewTableSource) =>
        dispatch(mapSceneOverviewTableChangeOverviewTableSource(overviewTableSource)),
    onRemoveTrajectoryFromSelection: (id) => dispatch(removeTrajectoryFromSelection(id)),
    onSaveWazeAlertData: (id, name, status, hectometrePost, type, subType) =>
        dispatch(saveWazeAlertData(id, name, status, hectometrePost, type, subType)),
    onSceneChanged: (scene) => dispatch(viewerApplicationSceneChanged(scene)),
    onSelectedNewTrafficCenter: (newTrafficCenter) => dispatch(selectedNewTrafficCenter(newTrafficCenter)),
    onSetNewDfineFilterStatusOnlyRvmNetwork: (newStatus) => dispatch(setNewDfineFilterStatusOnlyRvmNetwork(newStatus)),
    onSetNewPrefixFilter: (layerId, prefixes) => dispatch(setNewPrefixFilterForLayer(layerId, prefixes)),
    onSetNewTravelTimeLineOffset: (newOffset) => dispatch(mapSceneSettingsPanelSetNewTravelTimeLineOffset(newOffset)),
    onSetNewVisibleOverviewAllTable: (newStatus) => dispatch(setNewVisibleOverviewAllTable(newStatus)),
    onSetNewWazeAlertFilterStatusSet: (newStatus) => dispatch(setNewWazeAlertFilterStatusSet(newStatus)),
    onSetNewWazeAlertNdwKnownFilterStatus: (newStatus) => dispatch(setNewWazeAlertNdwKnownFilterStatus(newStatus)),
    onShowPopupForFeatures: (clickedFeatures, pointX, pointY) => dispatch(showPopupForFeatures(clickedFeatures, pointX, pointY)),
    onStoreMapSceneContainerViewPartHeight: (newHeight) => dispatch(storeMapSceneContainerViewPartHeight(newHeight)),
    onStoreNewSearchValue: (searchValue) => dispatch(storeNewSearchValue(searchValue)),
    onToggleShowRecordIds: (value) => dispatch(mapSceneSettingsPanelToggleShowRecordId(value)),
    onToggleTrajectorySummedSelection: () => dispatch(toggleTrajectorySummedSelection()),
    onUpdateMapIsReadyStatus: (status) => dispatch(updateMapIsReadyStatus(status)),
    onUpdateNotificationMessage: (message) => dispatch(mapSceneUpdateNotificationMessage(message)),
    onUpdateOverviewTableHeight: (newHeight) => dispatch(updateOverviewTableHeight(newHeight)),
    onUpdateSidebarStatus: (newStatus) => dispatch(updateSidebarStatus(newStatus)),
    onViewportChange: (newViewport: MapView) => dispatch(updateMapViewport(newViewport)),
    onToggleMapMovementAvailable: () => dispatch(mapSceneGeneralToggleMapMovementAvailable()),
    onHighlightContextMenu: (highlightContextMenu) => dispatch(mapSceneGeneralUpdateHighlightContextMenu(highlightContextMenu)),
    onUpdateHighlightFeatures: (features: MapFeature[] | null) => dispatch(mapSceneGeneralUpdateHighlightFeatures(features)),
    onUpdateSortTrafficMessagesByValue: (sortByValue) => dispatch(mapSceneTrafficInformationSortingValue(sortByValue)),
    onUpdateTrafficMessagesSortingDirection: (sortInDirection) => dispatch(mapSceneTrafficInformationSortingDirection(sortInDirection)),
    onSetNewFdVerifiedTrafficJamsFilterStatus: (newStatus) => dispatch(setNewFdVerifiedTrafficJamsFilterStatus(newStatus)),
    onSetSituationTypeFilter: (situationTypeFilters) => dispatch(setSituationTypeFilter(situationTypeFilters)),
    onUserHoverOverFeature: (features: MapFeature[], pointX, pointY) => dispatch(showPreviewPopupForFeatures(features, pointX, pointY)),
    onUserExistsHoverOverFeature: () => dispatch(hidePreviewPopup()),
    onToggleShowTrafficJamIcons: (show) => dispatch(mapSceneSettingsPanelToggleShowTrafficJamIcons(show)),
    numberOfFeaturesVisibleOnMap: (numberOfFeaturesVisible: number) => dispatch(mapSceneNumberOfFeaturesVisibleOnMap(numberOfFeaturesVisible))
});

export default connect(mapStateToProps, mapDispatchToProps)(MapScene);
