import {wrapCreateBrowserRouter} from '@sentry/react';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    Route
} from 'react-router-dom';
import {ProtectedRoute} from '@ndw/react-keycloak-authentication';
import Layout from '../components/Layout';
import {Routes} from './routes';
import NotSupportedScene from '../scenes/NotSupportedScene';
import MapScene from '../scenes/MapScene';
import LoadingScene from '../scenes/LoadingScene';
import NotFoundScene from '../scenes/NotFoundScene';
import RootErrorBoundary from '../components/RootErrorBoundary';

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
        <Route element={<Layout />} >
            <Route errorElement={<RootErrorBoundary />}>
                <Route
                    path={Routes.BASE}
                    element={<Navigate to={Routes.LOADING} replace />}
                />
                <Route
                    path={Routes.MAP}
                    element={
                        <ProtectedRoute onUnauthenticated="stop-render">
                            <MapScene />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={Routes.NOT_SUPPORTED}
                    element={<NotSupportedScene />}
                />
                <Route path={Routes.LOADING} element={<LoadingScene />} />
                <Route path="*" element={<NotFoundScene />} />
            </Route>
        </Route>,
    ),
    {
        future: {
            v7_relativeSplatPath: true,
            v7_fetcherPersist: true,
            v7_normalizeFormMethod: true,
            v7_partialHydration: true,
            v7_skipActionErrorRevalidation: true
        }
    }
);
