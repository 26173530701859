import React, {ReactElement} from 'react';
import {Props} from './Props';
import DateTimeFormatted from '../../../../../../components/DateTimeFormatted';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import StyledBoolean from '../../../../../../components/StyledBoolean';

class DialogDrip extends React.Component<Props> {

    render(): ReactElement {
        const {currentProperties, showRecordIds} = this.props;

        const hasImage = currentProperties.image.length > 0;
        const hasText = currentProperties.textLines.length > 0;

        return <table>
            <tbody>
                {showRecordIds && <tr>
                    <th>ID</th>
                    <td>{currentProperties.id}</td>
                </tr>}
                <tr>
                    <th>Naam</th>
                    <td>{currentProperties.name}</td>
                </tr>
                <tr>
                    <th>ID</th>
                    <td>{currentProperties.id}</td>
                </tr>
                <tr>
                    <th>Laatste update</th>
                    <td><DateTimeFormatted dateTime={currentProperties.timeLastSet}/></td>
                </tr>
                <tr>
                    <th>Actief</th>
                    <td>
                        <StyledBoolean value={currentProperties.active}/>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}><br/></td>
                </tr>
                {hasImage && this.renderImage(currentProperties.image, currentProperties.name)}
                {(!hasImage && hasText) && this.renderLines(currentProperties.textLines)}
            </tbody>
        </table>;
    }

    private renderLines = (lines: string) => {
        try {
            const json = JSON.parse(lines);

            if (Array.isArray(json)) {
                return <React.Fragment/>;
            }

            const keys = Object.keys(json).sort();

            return <tr className={css(styles.textLine)}>
                <td colSpan={2}>
                    {keys.map((key: string) => {
                        const value = json[key];
                        return <React.Fragment key={key}>
                            {value}
                            <br/>
                        </React.Fragment>;
                    })}
                </td>
            </tr>;
        } catch (e) {
            return <React.Fragment/>;
        }
    };

    private renderImage = (image: string, name: string) => {
        return <tr>
            <td colSpan={2} className={css(styles.imageContainer)}>
                <img
                    src={`data:image/png;base64,${image}`}
                    alt={`Huidig DRIP beeld van ${name}`}
                    title={`Huidig DRIP beeld van ${name}`}
                    className={css(styles.image)}
                />
            </td>
        </tr>;
    };

}

export default DialogDrip;
