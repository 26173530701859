import {ENVIRONMENT, IS_DEV, SENTRY_DSN, VERSION} from './applicationContext';
import {init, replayIntegration} from '@sentry/react';

if (!IS_DEV && SENTRY_DSN) {
    init({
        dsn: SENTRY_DSN,
        environment: ENVIRONMENT ?? undefined,
        release: VERSION ?? undefined,
        integrations: [replayIntegration()],
        tracesSampleRate: 1.0,
        tracePropagationTargets: [
            'localhost',
            /^https:\/\/ndwviewer(\.staging)?\.ndw\.nu/,
        ],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}
