/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DataSourceConfig = {
    id: string;
    name: string;
    dataStreamerId?: string;
    permissions?: Array<string>;
    loadBaseData?: boolean;
    refreshUpdate?: boolean;
    dataLoadMethod: DataSourceConfig.dataLoadMethod;
    updateInterval?: number;
    sourceLayer?: string;
    sourceConfiguration: Record<string, any>;
    searchProperty: string;
    websocketReload?: boolean;
    websocketUrl?: string;
    colorBlindSupported?: boolean;
    streamable?: boolean;
    proxyBackendUrl?: string;
    proxyPostPermissions?: Array<string>;
    proxyDeletePermissions?: Array<string>;
};
export namespace DataSourceConfig {
    export enum dataLoadMethod {
        VECTOR_TILES = 'VECTOR_TILES',
        GEO_JSON = 'GEO_JSON',
    }
}

