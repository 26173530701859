import React, {ChangeEvent, KeyboardEvent, ReactElement} from 'react';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import {Props} from './Props';

class StyledInput extends React.Component<Props> {

    handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const {value} = e.target;

        this.props.changeInput(value);
    };

    render(): ReactElement {
        const {
            disabled,
            type,
            value,
            placeholder,
            maxLength,
            minValue,
            maxValue,
            inputMode,
            pattern
        } = this.props;

        return <div className={css(styles.container)}>
            <input
                disabled={disabled}
                className={css(styles.input)}
                maxLength={maxLength}
                placeholder={placeholder}
                type={type}
                value={value}
                inputMode={inputMode}
                pattern={pattern}
                onChange={(e: ChangeEvent<HTMLInputElement>) => this.handleInputChange(e)}
                onKeyDown={(e) => this.handleKeyDown(e)}
                min={minValue}
                max={maxValue}
            />
        </div>;
    }

    private handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        const {submitInput, value} = this.props;

        if (e.key === 'Enter' && submitInput) {
            submitInput(value as string);
        }
    };
}

export default StyledInput;
