import React, {ReactElement} from 'react';
import {Props} from './Props';
import {css} from 'aphrodite';
import styles, {darkStyles} from './styles';
import ThemeContext from '../../contexts/ThemeContext';

class StyledLabel extends React.Component<Props> {

    static contextType = ThemeContext;
    context!: React.ContextType<typeof ThemeContext>;

    render(): ReactElement {
        const {for: htmlFor, title} = this.props;

        return <label
            htmlFor={htmlFor}
            className={css(styles.label, this.context.currentColorSchemeIsDark && darkStyles.label)}
        >
            {title}
        </label>;
    }

}

export default StyledLabel;
