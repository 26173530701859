import {css} from 'aphrodite/no-important';
import React, {ReactElement} from 'react';
import BooleanToggle from '../../../../../../components/BooleanToggle';
import StyledLabel from '../../../../../../components/StyledLabel';
import {OverviewAllTable} from '../../../../../../interfaces/OverviewAllTable';
import {Props} from './Props';
import styles from './styles';

class OverviewAllPane extends React.Component<Props> {

    toggleNewTable = (newTable: OverviewAllTable, currentTable: OverviewAllTable | null): void => {
        if (currentTable && currentTable.id === newTable.id) {
            return this.props.setNewVisibleOverviewAllTable(null, newTable);
        }

        this.props.setNewVisibleOverviewAllTable(newTable, currentTable);
    };

    render(): ReactElement {
        const {currentVisibleTable, overviewTables} = this.props;

        return <>
            <p>Kies hieronder welke tabel u wilt weergeven onder de kaartweergave.</p>

            <div className={css(styles.flexContainer)}>
                <div className={css(styles.flexItemLeft)}>
                    <StyledLabel title="Linker tabel" />
                    {overviewTables.map((overviewTable: OverviewAllTable) => <BooleanToggle
                        key={overviewTable.id}
                        active={currentVisibleTable !== null && currentVisibleTable.id === overviewTable.id}
                        title={overviewTable.name}
                        changeStatus={() => this.toggleNewTable(overviewTable, currentVisibleTable)}
                    />)}
                </div>
            </div>
        </>;
    }
}

export default OverviewAllPane;
