export const BACKEND_BASE_URL = document.getElementById('backend-base-url')?.getAttribute('content');
export const BACKEND_V2_BASE_URL = document.getElementById('backend-v2-base-url')?.getAttribute('content');
export const DATA_STREAMER_BASE_URL = document.getElementById('data-streamer-base-url')?.getAttribute('content');
export const SENTRY_DSN = document.getElementById('sentry-dsn')?.getAttribute('content');
export const ENVIRONMENT = document.getElementById('application-environment')?.getAttribute('content');
export const MAPS_SERVER_BASE_URL = document.getElementById('maps-server-base-url')?.getAttribute('content');
export const VERSION = document.getElementById('application-version')?.getAttribute('content');
export const IS_DEV = ENVIRONMENT === 'DEV';
export const IS_ACCEPTANCE = ENVIRONMENT === 'ACC';
export const IS_PRODUCTION = ENVIRONMENT === 'PROD';
export const RELOADING_INTERVALS = [5000, 10000, 15000, 20000, 30000, 60000];
export const ANALYTICS_ENABLED = document.getElementById('analytics-enabled')?.getAttribute('content') === '1';
