import {keycloakAuthenticationEpics} from '@ndw/react-keycloak-authentication';
import {combineEpics, Epic} from 'redux-observable';
import applicationEpics from './epics/applicationEpics';
import configurationEpics from './epics/configurationEpics';
import dialogEpics from './epics/dialogEpics';
import layoutEpics from './epics/layoutEpics';
import mapLayerConfigurationEpics from './epics/mapLayerConfigurationEpics';
import mapSceneEpics from './epics/mapSceneEpics';
import notificationModuleEpics from './epics/notificationModuleEpics';
import reloadingEpics from './epics/reloadingEpics';
import trafficCenterEpics from './epics/trafficCenterEpics';
import userSettingsEpics from './epics/userSettings';
import viewerWebsocketServiceEpics from './epics/viewerWebsocketService';


const rootEpics: Epic = combineEpics(
    applicationEpics,
    configurationEpics,
    dialogEpics,
    layoutEpics,
    keycloakAuthenticationEpics,
    mapSceneEpics,
    mapLayerConfigurationEpics,
    notificationModuleEpics,
    reloadingEpics,
    trafficCenterEpics,
    userSettingsEpics,
    viewerWebsocketServiceEpics
);

export default rootEpics;
