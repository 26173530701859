import React, {ReactElement} from 'react';
import {Props} from './Props';
import MapboxGL from '../../../../components/MapboxGl';
import {MapView} from '../../../../interfaces/Mapbox';
import {MapFeature} from '../../../../interfaces/MapFeature';

class Mapbox extends React.Component<Props> {

    onMapPositionUpdated = (viewport: MapView): void => {
        this.props.handleViewportChange(viewport);
    };

    onUserMapClick = (foundFeatures: MapFeature[], pointX: number, pointY: number): void => {
        this.props.onUserMapClick(foundFeatures, pointX, pointY);
    };

    render(): ReactElement {
        const {
            mapFlyToLocation,
            mapboxStyleUrl,
            mapLayers,
            mapSettings,
            dataSources,
            mapFilter,
            viewport,
            searchResults,
            showTrafficJamIcons,
            highlightFeatures,
            mapHasFinishedLoading,
            onUserHoverOverFeature,
            onUserExistsHoverOverFeature,
            onUserMapRightClick,
            numberOfFeaturesVisibleOnMap,
        } = this.props;

        return <MapboxGL
            mapFlyToLocation={mapFlyToLocation}
            styleUrl={mapboxStyleUrl}
            mapLayers={mapLayers}
            mapSettings={mapSettings}
            dataSources={dataSources}
            showTrafficJamIcons={showTrafficJamIcons}
            mapFilter={mapFilter}
            searchResults={searchResults}
            highlightFeatures={highlightFeatures}
            viewport={viewport}
            mapHasFinishedLoading={mapHasFinishedLoading}
            updateMapPosition={this.onMapPositionUpdated}
            onUserMapClick={this.onUserMapClick}
            onUserHoverOverFeature={onUserHoverOverFeature}
            onUserExistsHoverOverFeature={onUserExistsHoverOverFeature}
            onUserMapRightClick={onUserMapRightClick}
            numberOfFeaturesVisibleOnMap={numberOfFeaturesVisibleOnMap}
        />;
    }
}

export default Mapbox;
