import React, {ReactElement} from 'react';
import {Props} from './Props';
import DateTimeFormatted from '../../../../../../components/DateTimeFormatted';
import MessageAgeInMinutes from '../../../../../../components/MessageAgeInMinutes';

class DialogRushHourLane extends React.Component<Props> {

    render(): ReactElement {
        const {properties, showRecordIds} = this.props;

        return <table>
            <tbody>
                {showRecordIds && <tr>
                    <th>ID</th>
                    <td>{properties.id}</td>
                </tr>}
                <tr>
                    <th>Tijdstip melding</th>
                    <td>
                        <DateTimeFormatted
                            dateTime={properties.overallStartTime}
                        />
                    </td>
                </tr>
                <tr>
                    <th>Tijd sinds melding</th>
                    <td>
                        <MessageAgeInMinutes
                            time={properties.overallStartTime}
                        />
                    </td>
                </tr>
                <tr>
                    <th>Wegnummering</th>
                    <td>{properties.roadNumberAndSide}</td>
                </tr>
                <tr>
                    <th>Hoofdlocatie omschrijvig</th>
                    <td>{properties.primaryLocationDescription}</td>
                </tr>
                <tr>
                    <th>Hoofdlocatie hectometrering</th>
                    <td>{properties.primaryLocationKilometers}</td>
                </tr>
                <tr>
                    <th>Eindlocatie omschrijvig</th>
                    <td>{properties.secondaryLocationDescription}</td>
                </tr>
                <tr>
                    <th>Eindlocatie hectometrering</th>
                    <td>{properties.secondaryLocationKilometers}</td>
                </tr>
            </tbody>
        </table>;
    }
}

export default DialogRushHourLane;
