// export type TRAVEL_TIME_TAB = 'TRAVEL_TIME' | 'VELOCITY' | 'HEAT_MAP';
// export const TRAVEL_TIME_TAB_TRAVEL_TIME: TRAVEL_TIME_TAB = 'TRAVEL_TIME';
// export const TRAVEL_TIME_TAB_VELOCITY: TRAVEL_TIME_TAB = 'VELOCITY';
// export const TRAVEL_TIME_TAB_HEAT_MAP: TRAVEL_TIME_TAB = 'HEAT_MAP';

export enum TravelTimeTab {
    travelTime = 'TRAVEL_TIME',
    velocity = 'VELOCITY',
}

export interface State {
    currentTab: TravelTimeTab | null;
    tabTimestamp: number;
}
