import React, {ReactElement} from 'react';
import {AnyAction, Dispatch} from 'redux';
import {NOT_FOUND_SCENE, SCENES} from '../../scenes';
import {viewerApplicationSceneChanged} from '../../actions';
import {connect} from 'react-redux';
import {Props} from './Props';

class NotFoundScene extends React.Component<Props> {

    componentDidMount(): void {
        this.props.onSceneChanged(NOT_FOUND_SCENE);
    }

    render(): ReactElement {
        return <div>
            <h1>Pagina niet gevonden</h1>
        </div>;
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    onSceneChanged: (scene: SCENES) => dispatch(viewerApplicationSceneChanged(scene))
});

export default connect(mapStateToProps, mapDispatchToProps)(NotFoundScene);
