import type {RootState} from '../../reducer';
import {UrlSettings} from '../../interfaces/UrlSettings';

import {SortingDirection, SortingValue} from '../../interfaces/TrafficInformation';
import {router} from '../../routing/router';

export function saveChangeToUrl(state: RootState): void {
    const settings = getUrlSettingsFromCurrentState(state);
    changeUrlToSettings(settings);
}

export function getStringValueFromUrl(name: keyof UrlSettings, fallback: string): string {
    const re = new RegExp(`[#&]${name}=([^&]+)(&|$)`);
    const matchHash = re.exec(window.location.hash);

    if (matchHash?.length) {
        return matchHash[1];
    }

    return fallback;
}

export function getNumericValueFromUrl(name: keyof UrlSettings, fallback: number): number {
    const re = new RegExp(`[#&]${name}=([^&]+)(&|$)`);
    const matchHash = re.exec(window.location.hash);

    if (matchHash?.length) {
        const value = matchHash[1];
        if (!isNaN(parseFloat(value))) {
            return parseFloat(value);
        }
    }

    return fallback;
}

export function getBooleanValueFromUrl(name: keyof UrlSettings, fallback: boolean): boolean {
    const re = new RegExp(`[#&]${name}=([^&]+)(&|$)`);
    const matchHash = re.exec(window.location.hash);

    if (matchHash?.length) {
        const value = matchHash[1];
        if (!isNaN(parseInt(value, 10))) {
            return parseInt(value, 10) === 1;
        }
    }

    return fallback;
}

export function getObjectValueFromUrl<T>(name: keyof UrlSettings, fallback: T): T {
    const re = new RegExp(`[#&]${name}=([^&]+)(&|$)`);
    const matchHash = re.exec(window.location.hash);

    if (matchHash?.length) {
        try {
            return JSON.parse(decodeURIComponent(matchHash[1])) as T;
        } catch (error) {
            return fallback;
        }
    }

    return fallback;
}

function getUrlSettingsFromCurrentState({mapScene, userSettings}: RootState): UrlSettings {
    const newUrlObject: UrlSettings = {};

    const activeLayers: string[] = [];
    Object.keys(mapScene.mapboxLayers.layers).forEach((key: string) => {
        const mapLayer = mapScene.mapboxLayers.layers[key];
        if (mapLayer.isActive) {
            activeLayers.push(mapLayer.id);
        }
    });

    if (activeLayers.length) {
        newUrlObject.activeLayers = activeLayers;
    }
    if (mapScene.general.visibleTable) {
        newUrlObject.currentOverviewTable = mapScene.general.visibleTable.id;
    }
    if (mapScene.general.visibleTableSource) {
        newUrlObject.currentOverviewTableSource = mapScene.general.visibleTableSource.sourceType;
    }
    newUrlObject.mapFilterWazeAlertNdwKnown = mapScene.filterAndSearch.mapFilters.wazeAlertNdwKnown ? 1 : 0;
    newUrlObject.mapFilterWazeAlertStatusSet = mapScene.filterAndSearch.mapFilters.wazeAlertStatusSet ? 1 : 0;
    newUrlObject.mapFilterDfineRvmNetworkOnly = mapScene.filterAndSearch.mapFilters.dFineOnlyRvmNetwork ? 1 : 0;
    newUrlObject.mapFilterFdVerifiedTrafficJams = mapScene.filterAndSearch.mapFilters.fdVerifiedTrafficJams ? 1 : 0;
    newUrlObject.mapSettingTravelTimeFcdOffset = mapScene.general.mapSettings.travelTimeFcdOffset || 0;
    newUrlObject.showRecordIds = userSettings.showRecordId ? 1 : 0;
    newUrlObject.showTrafficJamIcons = userSettings.showTrafficJamIcons ? 1 : 0;
    newUrlObject.latitude = mapScene.general.viewport.latitude;
    newUrlObject.longitude = mapScene.general.viewport.longitude;
    newUrlObject.theme = userSettings.theme.index;
    if (userSettings.currentTrafficCenter) {
        newUrlObject.trafficCenter = userSettings.currentTrafficCenter.key;
    }
    newUrlObject.zoom = mapScene.general.viewport.zoom;
    newUrlObject.prefixFilters = JSON.stringify(mapScene.filterAndSearch.mapFilters.prefixFilter);
    newUrlObject.mapMovementAvailable = mapScene.general.mapMovementAvailable ? 1 : 0;
    newUrlObject.sortTrafficMessagesByRoadName = mapScene.trafficInformation.sortTrafficMessagesByValue === SortingValue.byRoadName ? 1 : 0;
    newUrlObject.sortTrafficMessagesInAscendingOrder = mapScene.trafficInformation.updateTrafficMessagesSortingDirection === SortingDirection.ascending ? 1 : 0;
    return newUrlObject;
}

function changeUrlToSettings(settings: UrlSettings) {
    const hash = Object
        .keys(settings)
        .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(settings[k] as unknown as string))
        .join('&');

    router.navigate({hash})
        .catch(error => {
            // eslint-disable-next-line no-console
            console.error('Failed to change URL hash', error);
        });
}
