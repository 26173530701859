import {combineEpics, Epic} from 'redux-observable';
import {filter, map, mergeMap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {keycloakInstanceCreated, keycloakService, refreshAccessToken} from '@ndw/react-keycloak-authentication';
import {createSearchParams} from 'react-router-dom';
import {viewerApplicationBooted} from '../../actions';
import {Routes} from '../../routing/routes';
import {RELOADING_INTERVALS} from '../../applicationContext';
import {startReloadingInterval} from '../../modules/ReloadingReducer/actions';
import {of} from 'rxjs';
import {viewerApiServiceReceivingSourceDataFailedWithUnauthorizedError} from '../../services/ViewerApiService/actions/sourceDataData';
import {viewerApiServiceReceivingHistoricalSummedDataFailedWithUnauthorizedError} from '../../services/ViewerApiService/actions/historicalSummedDataData';
import {viewerApiServiceReceivingHistoricalDataFailedWithUnauthorizedError} from '../../services/ViewerApiService/actions/historicalDataData';
import {
    viewerApiServiceReceivingWazeStatusDataFailedWithUnauthorizedError,
    viewerApiServiceUpdatingWazeStatusFailedWithUnauthorizedError
} from '../../services/ViewerApiService/actions/wazeStatusData';
import {viewerApiServiceLoadingTrafficCentersDataUnauthorizedError} from '../../services/ViewerApiService/actions/trafficCentersData';
import {viewerApiServiceReceivingWazeStatusCloseFailedWithUnauthorizedError} from '../../services/ViewerApiService/actions/wazeStatusCloseData';
import {viewerApiServiceSavingNotificationFailedWithUnauthorizedException} from '../../services/ViewerApiService/actions/notificationPane';
import {loadAccessData, loadPrefixes} from '../../services/ViewerApiService';
import {viewerApiServiceReceivingSearchFeaturesDataFailedWithUnauthorizedError} from '../../services/ViewerApiService/actions/loadSearchFeaturesData';
import {viewerApiServiceReceivingBridgeHistoryFailedWithUnauthorizedError} from '../../services/ViewerApiService/actions/loadBridgeHistory';
import {KEYCLOAK_CONFIG, REDIRECT_PARAM} from '../../constants';
import {userIsAuthenticated} from '../../modules/UserSettingsReducer/actions';


const startKeycloakAuthenticationOnApplicationBooted: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(viewerApplicationBooted)),
        mergeMap(() => {
            const params = createSearchParams({
                [REDIRECT_PARAM]: location.pathname,
            }).toString();
            return keycloakService.setupKeycloak(KEYCLOAK_CONFIG, `${Routes.LOADING}?${params}${location.hash}`);
        })
    );

const authenticatedOnKeycloakInstanceCreated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(keycloakInstanceCreated)),
        filter(action => action.payload.authenticated),
        map(() => userIsAuthenticated())
    );

const loadAccessDataOnUserIsAuthenticated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(userIsAuthenticated)),
        mergeMap(() => loadAccessData())
    );

const loadPrefixDataOnUserIsAuthenticated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(userIsAuthenticated)),
        mergeMap(() => loadPrefixes())
    );


const startReloadingTwentySecondsOnUserIsAuthenticated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(userIsAuthenticated)),
        mergeMap(() => of(...RELOADING_INTERVALS).pipe(map((value) => startReloadingInterval(value))))
    );

const refreshAccessTokenOnUnauthorizedResponseFromViewerApiService: Epic = (action$) => action$
    .pipe(
        filter(isActionOf([
            viewerApiServiceReceivingHistoricalSummedDataFailedWithUnauthorizedError,
            viewerApiServiceReceivingSourceDataFailedWithUnauthorizedError,
            viewerApiServiceReceivingHistoricalDataFailedWithUnauthorizedError,
            viewerApiServiceReceivingBridgeHistoryFailedWithUnauthorizedError,
            viewerApiServiceUpdatingWazeStatusFailedWithUnauthorizedError,
            viewerApiServiceReceivingWazeStatusDataFailedWithUnauthorizedError,
            viewerApiServiceLoadingTrafficCentersDataUnauthorizedError,
            viewerApiServiceReceivingWazeStatusCloseFailedWithUnauthorizedError,
            viewerApiServiceSavingNotificationFailedWithUnauthorizedException,
            viewerApiServiceReceivingSearchFeaturesDataFailedWithUnauthorizedError
        ])),
        map(() => refreshAccessToken())
    );

const applicationEpics = combineEpics(
    startKeycloakAuthenticationOnApplicationBooted,
    authenticatedOnKeycloakInstanceCreated,
    loadAccessDataOnUserIsAuthenticated,
    startReloadingTwentySecondsOnUserIsAuthenticated,
    refreshAccessTokenOnUnauthorizedResponseFromViewerApiService,
    loadPrefixDataOnUserIsAuthenticated
);

export default applicationEpics;
