/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DummyDTO } from '../models/DummyDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DummyRestControllerService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public handleSnapshot(
        requestBody: Array<DummyDTO>,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/proxy/dummy/dummy/snapshot',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public handleIncrement(
        requestBody: Array<DummyDTO>,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/proxy/dummy/dummy/increment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
