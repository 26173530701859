import React, {ReactElement} from 'react';
import {Props} from './Props';
import DateTimeFormatted from '../../../../../../components/DateTimeFormatted';
import MessageAgeInMinutes from '../../../../../../components/MessageAgeInMinutes';
import StyledBoolean from '../../../../../../components/StyledBoolean';

class DialogTrafficMessage extends React.Component<Props> {

    render(): ReactElement {
        const {properties, showRecordIds} = this.props;

        return <table>
            <tbody>
                {showRecordIds && <tr>
                    <th>ID</th>
                    <td>{properties.id}</td>
                </tr>}
                <tr>
                    <th>Type</th>
                    <td>{properties.type}</td>
                </tr>
                <tr>
                    <th>Subtype</th>
                    <td>{properties.subType}</td>
                </tr>
                <tr>
                    <th>Tijdstip eerste melding</th>
                    <td>
                        <DateTimeFormatted
                            dateTime={properties.overallStartTime}
                        />
                    </td>
                </tr>
                <tr>
                    <th>Tijd sinds eerste melding</th>
                    <td>
                        <MessageAgeInMinutes
                            time={properties.overallStartTime}
                        />
                    </td>
                </tr>
                <tr>
                    <th>Tijdstip laatste aanpassing</th>
                    <td>
                        <DateTimeFormatted
                            dateTime={properties.versionTime}
                        />
                    </td>
                </tr>
                <tr>
                    <th>Tijd sinds laatste aanpassing</th>
                    <td>
                        <MessageAgeInMinutes
                            time={properties.versionTime}
                        />
                    </td>
                </tr>
                <tr>
                    <th>Bron</th>
                    <td>{properties.source}</td>
                </tr>
                <tr>
                    <th>Veiligheidsbericht afgegeven</th>
                    <td>
                        <StyledBoolean
                            value={properties.safetyRelatedMessage}
                        />
                    </td>
                </tr>
                { properties.type === 'VehicleObstruction' && <tr>
                    <th>Betrokken voertuig</th>
                    <td>{properties.subType}</td>
                </tr>}
                { properties.type === 'VehicleObstruction' && <tr>
                    <th>Bergingsstatus</th>
                    <td>{properties.mobilityOfObstruction}</td>
                </tr>}
                { properties.type === 'Accident' && <tr>
                    <th>Accident type</th>
                    <td>{properties.subType}</td>
                </tr>}
                <tr>
                    <th>Reden</th>
                    <td>{properties.cause}</td>
                </tr>
                <tr>
                    <th>Opmerking</th>
                    <td>{properties.comment}</td>
                </tr>
                { properties.type === 'AbnormalTraffic' && <tr>
                    <th>Filelengte</th>
                    <td>{this.buildTrafficJamQueueLengthText(properties.trafficJamQueueLength)}</td>
                </tr>}
            </tbody>
        </table>;
    }

    private buildTrafficJamQueueLengthText = (trafficJamQueueLength: number | null): string => {
        if (!trafficJamQueueLength || (trafficJamQueueLength as never) === 'null') {
            return '(Geen file lengte in de data)';
        }

        return (trafficJamQueueLength / 1000) + 'km';
    };
}

export default DialogTrafficMessage;
