import React, {ReactElement} from 'react';
import {Props} from './Props';
import Plot from 'react-plotly.js';
import {State} from './State';
import {Datum, PlotData} from 'plotly.js';
import {cloneDeep} from 'lodash';

class SimpleGraph extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            plotData: {
                data: [],
                layout: {
                    width: props.width,
                    title: props.title,
                    autosize: true,
                    margin: {
                        l: 0,
                        t: 0,
                        r: 0,
                        b: 0
                    },
                    yaxis: {
                        automargin: true
                    },
                    xaxis: {
                        automargin: true
                    },
                    legend: {
                        orientation: 'h'
                    }
                },
                frames: []
            },
            graphLegendStatus: {}
        };
    }

    render(): ReactElement {
        const {data, revision} = this.props;
        const {plotData} = this.state;

        return <>
            <div style={{width: '100%'}}>
                <Plot
                    data={this.plotDataWithDutchTimes(data)}
                    revision={revision}
                    layout={plotData.layout}
                    frames={plotData.frames || undefined}
                />
            </div>
        </>;
    }

    private plotDataWithDutchTimes(plotData: Partial<PlotData>[]):Partial<PlotData>[] {
        return cloneDeep(plotData).map(d => {
            if (d.x instanceof Array) {
                const x = d.x?.map(this.toZuluDate);
                return {...d, x} as Partial<PlotData>;
            } else if (!d.x) {
                return d;
            } else {
                const x = Array.from(d.x).map(this.toZuluDate);
                return {...d, x} as Partial<PlotData>;
            }
        });
    }

    private toZuluDate(datum: Datum | Datum[] | null): Datum {
        if (typeof datum === 'string' && datum.slice(-1) !== 'z') {
            return new Date(`${datum}z`);
        } else {
            throw new Error('Unexpected "Datum" value in SimpleGraph');
        }
    }

}

export default SimpleGraph;
