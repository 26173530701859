import React, {ReactElement} from 'react';
import StyledLabel from '../../../../../../components/StyledLabel';
import BooleanToggle from '../../../../../../components/BooleanToggle';
import {Props} from './Props';
import {mapLayer} from '../../../../../../models';
import Select from 'react-select';
import StyledInput from '../../../../../../components/StyledInput';
import {SearchFeature, SearchResult} from '../../../../../../interfaces/SearchFeature';
import SearchItem from './components/SearchItem';
import {PrefixFilterOption} from '../../../../../../interfaces/PrefixFilterOptions';
import ThemeContext from '../../../../../../contexts/ThemeContext';
import {selectStyles} from './styles';

class FilterPane extends React.Component<Props> {
    static contextType = ThemeContext;
    context!: React.ContextType<typeof ThemeContext>;

    render(): ReactElement {
        const {
            mapFilter,
            mapLayers,
            prefixes,
            currentSearchValue,
            searchResults,
            setSituationTypeFilter,
            setNewWazeAlertNdwKnownFilterStatus,
            setNewWazeAlertFilterStatusSet,
            setNewFdValidatedTrafficJamsFilterStatus,
            setNewDfineFilterStatusOnlyRvmNetwork,
            onSetNewPrefixFilter,
        } = this.props;
        const mapLayerValues = Object.values(mapLayers);

        return <>
            <p><i>Filter de actieve lagen met de beschikbare filter opties om zo de data set naar uw wens te
                krijgen.</i></p>
            <br/>
            {mapLayer.getLayerIsActive('trafficMessageOtis', mapLayerValues) && <>
                <h3>RWS verkeersberichten (OTIS)</h3>
                <StyledLabel title='OTIS: Verberg types'/>
                <Select
                    styles={selectStyles(this.context)}
                    isMulti={true}
                    options={mapFilter.situationTypeFilterOptions}
                    value={mapFilter.situationTypeFilters}
                    onChange={values => setSituationTypeFilter(Array.isArray(values) ? values : [])}
                />

            </>}

            {mapLayer.getLayerIsActive('wazeAlerts', mapLayerValues) && <>
                <h3>Meldingen weggebruikers incidenten (Gebeurtenissen)</h3>
                <StyledLabel title='Waze melding: Verberg NDW bekende berichten'/>
                <BooleanToggle
                    active={mapFilter.wazeAlertNdwKnown}
                    title='Verberg NDW bekende berichten'
                    changeStatus={() => setNewWazeAlertNdwKnownFilterStatus(!mapFilter.wazeAlertNdwKnown)}
                />

                <StyledLabel title='Waze melding: Verberg behandelde berichten'/>
                <BooleanToggle
                    active={mapFilter.wazeAlertStatusSet}
                    title='Verberg behandelde berichten'
                    changeStatus={() => setNewWazeAlertFilterStatusSet(!mapFilter.wazeAlertStatusSet)}
                />
                <hr/>
            </>}

            {mapLayer.getLayerIsActive('fd', mapLayerValues) && <>
                <h3>Meldingen weggebruikers incidenten (Gebeurtenissen)</h3>
                <StyledLabel title='Ongevalideerde files: Verberg files die in de gevalideerde files feed voorkomen'/>
                <BooleanToggle
                    active={mapFilter.fdVerifiedTrafficJams}
                    title='Verberg NDW bekende berichten'
                    changeStatus={() => setNewFdValidatedTrafficJamsFilterStatus(!mapFilter.fdVerifiedTrafficJams)}
                />
                <hr/>
            </>}

            {mapLayer.getLayerIsActive('dfine', mapLayerValues) && <>
                <h3>SRTI uit Sensordata (L3) (Voertuigdata)</h3>
                <StyledLabel title='SRTI uit Sensordata (L3): Alleen RVM netwerk meldingen weergeven'/>
                <BooleanToggle
                    active={mapFilter.dFineOnlyRvmNetwork}
                    title='Toon alleen RVM meldingen'
                    changeStatus={() => setNewDfineFilterStatusOnlyRvmNetwork(!mapFilter.dFineOnlyRvmNetwork)}
                />
                <hr/>
            </>}

            {mapLayer.getLayerIsActive('flowSpeed', mapLayerValues) && <>
                <h3>Snelheid en intensiteiten o.b.v. lussen (Algemeen verkeersbeeld)</h3>
                <StyledLabel title='Filter op prefixes'/>
                <Select
                    isMulti={true}
                    options={prefixes.flowSpeed}
                    value={mapFilter.prefixFilter.flowSpeed.prefixes
                        .map<PrefixFilterOption>((prefix) => ({value: prefix, label: prefix}))}
                    onChange={(values) =>
                        onSetNewPrefixFilter(
                            'flowSpeed',
                            Array.isArray(values) ? values.map((value: {
                                    value: string,
                                    label: string
                                }) => value.value) :
                                []
                        )}
                />
                <hr/>
            </>}
            {mapLayer.getLayerIsActive('travelTimeFcd', mapLayerValues) && <>
                <h3>Snelheid o.b.v. FCD Trajecten (Algemeen verkeersbeeld)</h3>
                <StyledLabel title='Filter op prefixes'/>
                <Select
                    isMulti={true}
                    options={prefixes.travelTimeFcd}
                    value={mapFilter.prefixFilter.travelTimeFcd.prefixes
                        .map<PrefixFilterOption>((prefix) => ({value: prefix, label: prefix}))}
                    onChange={(values) =>
                        onSetNewPrefixFilter(
                            'travelTimeFcd',
                            Array.isArray(values) ? values.map((value: {
                                    value: string,
                                    label: string
                                }) => value.value) :
                                []
                        )}
                />
                <hr/>
            </>}

            <StyledLabel title='Uw zoekopdracht'/>
            <StyledInput
                type='text'
                value={currentSearchValue}
                changeInput={(newValue: string) => this.handleSearchInputChange(newValue)}
            />

            {searchResults && searchResults.searchResults.map((searchResult: SearchResult, index: number) =>
                <React.Fragment key={index}>
                    <p><strong>{searchResult.name}</strong></p>
                    {searchResult.results.map((searchFeature: SearchFeature, resultIndex: number) => <SearchItem
                        currentSearchValue={currentSearchValue}
                        searchFeature={searchFeature}
                        clickedOnItem={this.clickedOnFeature}
                        key={resultIndex}
                    />)}
                    {searchResult.results.length === 0 && <p>Geen resultaten voor deze data stroom</p>}
                    {searchResult.results.length === 150 &&
                        <p><strong><i>Meer dan 150 resultaten, verfijn uw zoekopdracht voor betere
                            resultaten</i></strong>
                        </p>}
                    <br/>
                </React.Fragment>)}

            {(searchResults && searchResults.searchResults.length === 0) &&
                <p>Geen resultaten gevonden</p>}
        </>;
    }

    private handleSearchInputChange = (e: string) => {
        this.props.changeSearchFieldValue(e);
    };

    private clickedOnFeature = (item: SearchFeature) => {
        if (!item.coordinates.latitude || !item.coordinates.longitude) {
            return;
        }

        this.props.onFlyToLocation(item.coordinates.latitude, item.coordinates.longitude, 15);
    };

}

export default FilterPane;
