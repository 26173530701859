import React, {ReactElement} from 'react';
import {Props} from './Props';
import {State} from './State';
import {NotificationDTO} from '../../../../../../generated/ViewerApiClient';
import {css} from 'aphrodite/no-important';
import styles from './styles';

class NotificationItem extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            notificationIsUnread: false,
            notificationMessage: null,
            notificationMessageVisible: false
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const {currentNotification} = this.props;
        if (currentNotification) {
            if (prevProps.currentNotification && currentNotification.id !== prevProps.currentNotification.id) {
                this.storeNewNotification(currentNotification);
            } else if (!prevProps.currentNotification) {
                this.storeNewNotification(currentNotification);
            }
        } else if (!currentNotification && !!prevProps.currentNotification) {
            this.storeNewNotification(null);
        }
    }

    render(): ReactElement {
        const {notificationIsUnread, notificationMessage} = this.state;

        if (notificationMessage) {
            return this.renderNotification(notificationMessage, notificationIsUnread);
        }

        return <React.Fragment/>;
    }

    private storeNewNotification = (notification: NotificationDTO | null) => {
        if (notification) {
            const lastNotificationID = localStorage.getItem('lastNotificationID');
            this.setState({
                notificationIsUnread: notification.id !== lastNotificationID,
                notificationMessage: notification.message
            });
        } else {
            this.setState({
                notificationIsUnread: false,
                notificationMessage: null
            });
        }
    };

    private renderNotification = (notification: string, isUnread: boolean) => {
        const {notificationMessageVisible} = this.state;

        return <button
            type="button"
            className={css(styles.notification)} onClick={() => this.handleClickNotificationBell()}
            aria-label={notificationMessageVisible ? 'Verberg notificatie' : 'Toon notificatie'}
            aria-expanded={notificationMessageVisible}
            aria-controls="notification-item_message"
        >
            <span className={css(isUnread && styles.notificationUnread) + ' fas fa-bell'}/>

            <div
                id="notification-item_message"
                className={css(styles.notificationText, !notificationMessageVisible && styles.notificationTextHidden)}
                aria-hidden={!notificationMessageVisible}
            >
                {notification}
            </div>
        </button>;
    };

    private handleClickNotificationBell = () => {
        const {notificationMessageVisible} = this.state;

        this.setState({
            notificationMessageVisible: !notificationMessageVisible
        }, () => {
            const stateCallback = this.state;

            if (stateCallback.notificationMessageVisible && stateCallback.notificationIsUnread) {
                this.markNotificationAsRead();
            }
        });
    };

    private markNotificationAsRead = () => {
        this.setState({
            notificationIsUnread: false
        });
        localStorage.setItem('lastNotificationID', this.props.currentNotification!.id);
    };
}

export default NotificationItem;
