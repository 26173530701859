import React from 'react';
import {Props} from './Props';
import {formatTime} from '../../utils/DateUtil';

const TimeFormatted: React.FunctionComponent<Props> = ({dateTime}) => {
    const date = new Date(dateTime);
    if (isNaN(date.getTime())) {
        return <>-</>;
    }

    const formatted = formatTime(date);
    return <span>{formatted}</span>;
};

export default TimeFormatted;
