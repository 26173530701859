import {StyleSheet} from 'aphrodite/no-important';
import {NAV_BAR_COLORLINE_HEIGHT} from '../../styles';

const styles = StyleSheet.create({
    line: {
        height: NAV_BAR_COLORLINE_HEIGHT
    }
});

export default styles;
