import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {debounceTime, filter, mergeMap, ignoreElements, map, tap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {getNumericValueFromUrl, getStringValueFromUrl, saveChangeToUrl} from '../../services/UrlService';
import {urlServiceUpdateSettingsInUrl} from '../../services/UrlService/actions';
import {RootState} from '../../reducer';
import {EMPTY, of} from 'rxjs';
import {
    disableMapLayer,
    enableMapLayer,
    updateMapIsReadyStatus,
    updateMapViewport
} from '../../scenes/MapScene/actions/mapBoxComponent';
import {
    changeCurrentTheme,
    changeCurrentTrafficCenter,
    userSettingsReducerShowRecordIds,
    userSettingsReducerShowTrafficJamIcons
} from '../../modules/UserSettingsReducer/actions';
import {
    mapSceneOverviewTableChangeOverviewTableSource,
    setNewVisibleOverviewAllTable
} from '../../scenes/MapScene/actions/overviewTableComponent';
import {
    setNewDfineFilterStatusOnlyRvmNetwork,
    setNewFdVerifiedTrafficJamsFilterStatus,
    setNewPrefixFilterForLayer,
    setNewWazeAlertFilterStatusSet,
    setNewWazeAlertNdwKnownFilterStatus
} from '../../scenes/MapScene/actions/reducers/filterAndSearch';
import {mapSceneMapboxLayersUpdateMapLayerConfiguration} from '../../scenes/MapScene/actions/reducers/mapboxLayers';
import {flyToLocation, mapSceneGeneralToggleMapMovementAvailable} from '../../scenes/MapScene/actions';
import {
    mapSceneTrafficInformationSortingDirection,
    mapSceneTrafficInformationSortingValue
} from '../../scenes/MapScene/actions/reducers/trafficInformation';
import {MAP_DEFAULT_LATITUDE, MAP_DEFAULT_LONGITUDE, MAP_DEFAULT_ZOOM} from '../../constants';

const enableMapLayersOnMapLoadingIsFinished: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        filter(isActionOf([
            updateMapIsReadyStatus,
            mapSceneMapboxLayersUpdateMapLayerConfiguration
        ])),
        filter(() => state$.value.mapScene.general.mapIsReady),
        filter(() => Object.keys(state$.value.mapScene.data.sources).length > 0),
        mergeMap(() => {
            const urlParameterValue = getStringValueFromUrl('activeLayers', '');
            if (urlParameterValue.length === 0) {
                return EMPTY;
            }

            return of(...decodeURIComponent(urlParameterValue).split(',')).pipe(map((value) => {
                return enableMapLayer(value);
            }));
        })
    );

const updateViewportOnMapLoadingIsFinished: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(updateMapIsReadyStatus)),
        filter(action => action.payload.status),
        map(() => {
            const latitude = getNumericValueFromUrl('latitude', MAP_DEFAULT_LATITUDE);
            const longitude = getNumericValueFromUrl('longitude', MAP_DEFAULT_LONGITUDE);
            const zoom = getNumericValueFromUrl('zoom', MAP_DEFAULT_ZOOM);
            return flyToLocation(latitude, longitude, zoom);
        }),
    );

const updateSettingsInUrlOnChaneInformation: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf([
            changeCurrentTheme,
            changeCurrentTrafficCenter,
            disableMapLayer,
            enableMapLayer,
            setNewVisibleOverviewAllTable,
            mapSceneOverviewTableChangeOverviewTableSource,
            setNewWazeAlertNdwKnownFilterStatus,
            setNewWazeAlertFilterStatusSet,
            updateMapViewport,
            userSettingsReducerShowRecordIds,
            userSettingsReducerShowTrafficJamIcons,
            setNewDfineFilterStatusOnlyRvmNetwork,
            setNewPrefixFilterForLayer,
            mapSceneGeneralToggleMapMovementAvailable,
            mapSceneTrafficInformationSortingValue,
            mapSceneTrafficInformationSortingDirection,
            setNewFdVerifiedTrafficJamsFilterStatus
        ])),
        map(() => urlServiceUpdateSettingsInUrl())
    );

const saveChangesToUrlOnDebouncedUpdateSettingsInUrl: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        filter(isActionOf(urlServiceUpdateSettingsInUrl)),
        filter(() => state$.value.mapScene.general.mapIsReady),
        debounceTime(1000),
        tap(() => saveChangeToUrl(state$.value)),
        ignoreElements()
    );

const configurationEpics: Epic = combineEpics(
    enableMapLayersOnMapLoadingIsFinished,
    updateViewportOnMapLoadingIsFinished,
    updateSettingsInUrlOnChaneInformation,
    saveChangesToUrlOnDebouncedUpdateSettingsInUrl
);

export default configurationEpics;
