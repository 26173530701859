import {StyleSheet} from 'aphrodite/no-important';
import {NAV_BAR_COLORLINES_TOTAL_HEIGHT, NAV_BAR_HEIGHT} from '../../styles';

const fadeIn = {
    '0%': {
        opacity: 0
    },
    '100%': {
        opacity: 1
    }
};

const styles = StyleSheet.create({
    wrapper: {
        'height': `calc(100% - ${NAV_BAR_HEIGHT + NAV_BAR_COLORLINES_TOTAL_HEIGHT}px)`,
        'display': 'flex',
        'flex-direction': 'column',
        'gap': '2rem',
        'align-items': 'center',
        'padding-block-start': '10rem'
    },
    loadingMessage: {
        opacity: 0,
        animationName: [fadeIn],
        animationDuration: '.3s',
        animationDelay: '1s',
        animationFillMode: 'forwards'
    }
});

export default styles;
