import {css} from 'aphrodite/no-important';
import React from 'react';
import {useLocation} from 'react-router-dom';
import {
    ENVIRONMENT,
    IS_ACCEPTANCE, IS_PRODUCTION,
    VERSION
} from '../../../../applicationContext';
import ndwLogo from '../../../../assets/logo-ndw.svg';
import {useThemeContext} from '../../../../contexts/ThemeContext';
import {Routes} from '../../../../routing/routes';
import {getNativeWindow} from '../../../../services/WindowService';
import {ACCEPTANCE_STYLE, ACCEPTANCE_STYLE_DARK} from '../../../../styles';
import ColorLine from '../../../ColorLine';
import NavbarItem from './components/NavbarItem';
import NavbarItemCollection from './components/NavbarItemCollection';
import NotificationItem from './components/NotificationItem';
import UserMenu from './components/UserMenu';
import {Props} from './Props';
import styles, {darkStyles} from './styles';
import ButtonIcon from '../../../ButtonIcon';
import {useAuthenticationContext} from '@ndw/react-keycloak-authentication';

const SHARE_LINK_LABEL =
    'Deel je huidige NDW Viewer configuratie via een email';

const NavigationBar: React.FunctionComponent<Props> = ({
    changeCurrentRoute,
    notification,
    userProfile,
    onRequestedLogout
}) => {
    const {currentColorSchemeIsDark} = useThemeContext();
    const {isAuthenticated} = useAuthenticationContext();
    const location = useLocation();

    const {colorLinePrimary, colorLineSecondary} = React.useMemo(() => {
        if (!IS_ACCEPTANCE) {
            return {
                colorLinePrimary: '#F7893C',
                colorLineSecondary: '#1DCAD3'
            };
        }

        return {
            colorLinePrimary: currentColorSchemeIsDark ? ACCEPTANCE_STYLE_DARK.secondary : ACCEPTANCE_STYLE.secondary,
            colorLineSecondary: currentColorSchemeIsDark ? ACCEPTANCE_STYLE_DARK.tertiary : ACCEPTANCE_STYLE.tertiary
        };
    }, [currentColorSchemeIsDark]);

    const openEmailWithShareLink = (): void => {
        const emailAddress = '';
        const subject = 'NDW Viewer configuratie';
        const body = encodeURIComponent(`Beste lezer,

Met deze link kan je de NDW Viewer openen met mijn configuratie: ${document.location.href}.

Met vriendelijke groet,`);

        const window = getNativeWindow().open(`mailto:${emailAddress}?subject=${subject}&body=${body}`,);
        setTimeout(() => {
            if (window && !window.closed) {
                window.close();
            }
        }, 100);
    };

    return (
        <div>
            <div
                className={css(
                    styles.navigationContainer,
                    currentColorSchemeIsDark && darkStyles.navigationContainer,
                )}
            >
                <div className={css(styles.logoContainer)}>
                    <img
                        src={ndwLogo}
                        title="NDW"
                        className={css(styles.logo)}
                        alt="NDW"
                    />
                    <div className={css(styles.versionText)}>
                        {VERSION} {!IS_PRODUCTION && <span>({ENVIRONMENT})</span>}
                    </div>
                </div>

                <NavbarItemCollection alignNavbar="LEFT">
                    <NavbarItem
                        active={location.pathname === Routes.MAP}
                        title="Viewer"
                        onClick={() => {
                            changeCurrentRoute(Routes.MAP);
                        }}
                    />
                </NavbarItemCollection>

                {isAuthenticated && (<NavbarItemCollection alignNavbar="RIGHT">
                    <li>
                        <NotificationItem currentNotification={notification} />
                    </li>

                    <li>
                        <ButtonIcon
                            iconClassName="fas fa-share-square"
                            onClick={openEmailWithShareLink}
                            aria-label={SHARE_LINK_LABEL}
                            title={SHARE_LINK_LABEL}
                            addHoverStyle
                        />
                    </li>

                    <li>
                        <UserMenu
                            userProfile={userProfile}
                            onRequestedLogout={onRequestedLogout}
                        />
                    </li>
                </NavbarItemCollection>)}
            </div>
            <ColorLine
                primaryColor={colorLinePrimary}
                secondaryColor={colorLineSecondary}
            />
        </div>
    );
};

export default NavigationBar;
