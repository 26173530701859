/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProxyExchangeObject } from '../models/ProxyExchangeObject';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ProxyControllerService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param proxyExchange
     * @param dataSourceId
     * @returns any OK
     * @throws ApiError
     */
    public getProxy(
        proxyExchange: ProxyExchangeObject,
        dataSourceId: string,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/proxy/{dataSourceId}/**',
            path: {
                'dataSourceId': dataSourceId,
            },
            query: {
                'proxyExchange': proxyExchange,
            },
        });
    }
    /**
     * @param proxyExchange
     * @param dataSourceId
     * @returns any OK
     * @throws ApiError
     */
    public postProxy(
        proxyExchange: ProxyExchangeObject,
        dataSourceId: string,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/proxy/{dataSourceId}/**',
            path: {
                'dataSourceId': dataSourceId,
            },
            query: {
                'proxyExchange': proxyExchange,
            },
        });
    }
    /**
     * @param proxyExchange
     * @param dataSourceId
     * @returns any OK
     * @throws ApiError
     */
    public deleteProxy(
        proxyExchange: ProxyExchangeObject,
        dataSourceId: string,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/proxy/{dataSourceId}/**',
            path: {
                'dataSourceId': dataSourceId,
            },
            query: {
                'proxyExchange': proxyExchange,
            },
        });
    }
}
