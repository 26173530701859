import {combineEpics, Epic} from 'redux-observable';
import {filter, map, mergeMap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {loadNotificationData} from '../../services/ViewerApiService';
import {
    viewerApiServiceReceivedNotificationMessage,
    viewerApiServiceSavedNotificationMessage
} from '../../services/ViewerApiService/actions/notificationPane';
import {notificationModuleSaveNewNotification} from '../../modules/NotificationReducer/actions';
import {userIsAuthenticated} from '../../modules/UserSettingsReducer/actions';

const loadNotificationDataOnUserIsAuthenticated: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(userIsAuthenticated)),
        mergeMap(() => loadNotificationData())
    );

const loadNotificationOnViewerApiServiceSavedNotificationMessage: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(viewerApiServiceSavedNotificationMessage)),
        mergeMap(() => loadNotificationData())
    );

const onViewerApiServiceReceivedNotificationMessage: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(viewerApiServiceReceivedNotificationMessage)),
        map(({payload: {notification}}) => notificationModuleSaveNewNotification(notification))
    );

const notificationModuleEpics: Epic = combineEpics(
    loadNotificationDataOnUserIsAuthenticated,
    loadNotificationOnViewerApiServiceSavedNotificationMessage,
    onViewerApiServiceReceivedNotificationMessage
);

export default notificationModuleEpics;
