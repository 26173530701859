import React, {ReactElement} from 'react';
import {Props} from './Props';
import DateTimeFormatted from '../../../../../../components/DateTimeFormatted';
import MessageAgeInMinutes from '../../../../../../components/MessageAgeInMinutes';

class DialogSituationOnda extends React.Component<Props> {


    private static convertVehicleObstructionType(value: string): string {
        switch (value) {
            case 'brokenDownVehicle':
                return 'Auto met pech';
            case 'brokenDownHeavyLorry':
                return 'Vrachtwagen met pech';
            default:
                return value;
        }
    }


    render(): ReactElement {
        const {properties, showRecordIds} = this.props;

        return <>
            <table>
                <tbody>
                    {showRecordIds && <tr>
                        <th>ID</th>
                        <td>{properties.id}</td>
                    </tr>}
                    <tr>
                        <th>Tijd melding</th>
                        <td><DateTimeFormatted dateTime={properties.overallStartTime}/></td>
                    </tr>
                    <tr>
                        <th>Bron</th>
                        <td>{properties.source}</td>
                    </tr>
                    <tr>
                        <th>Locatie</th>
                        <td>
                            {/* tslint:disable-next-line:max-line-length */}
                            {properties.primaryLocationDescription}
                        </td>
                    </tr>
                </tbody>
            </table>

            <table>
                <tbody>
                    <tr>
                        <th>Soort</th>
                        <td>{properties.type}</td>
                    </tr>
                    <tr>
                        <th>Tijd melding</th>
                        <td><DateTimeFormatted dateTime={properties.overallStartTime}/></td>
                    </tr>
                    <tr>
                        <th>Tijd laatste aanpassing</th>
                        <td><DateTimeFormatted dateTime={properties.versionTime}/></td>
                    </tr>

                    <tr>
                        <th>Tijd melding</th>
                        <td><MessageAgeInMinutes time={properties.overallStartTime}/></td>
                    </tr>
                    <tr>
                        <th>Betrokken voertuig</th>
                        <td>{DialogSituationOnda.convertVehicleObstructionType(properties.subType)}</td>
                    </tr>
                    <tr>
                        <th>Melder</th>
                        <td>{properties.reporter}</td>
                    </tr>
                    <tr>
                        <th>Melder referentie</th>
                        <td>{properties.reporterReference}</td>
                    </tr>
                    <tr>
                        <th>Bergingsstatus</th>
                        <td>{this.convertMobilityOfObstruction(properties.mobilityOfObstruction)}</td>
                    </tr>
                </tbody>
            </table>
        </>;
    }

    private convertMobilityOfObstruction = (value: string): string => {
        if (value === 'stationary') {
            return 'Stilstaand';
        } else {
            return value;
        }
    };
}

export default DialogSituationOnda;
