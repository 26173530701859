import {StyleSheet} from 'aphrodite/no-important';

const rotateKeyframes = {
    'to': {
        rotate: '360deg'
    }
};

const styles = StyleSheet.create({
    loader: {
        'display': 'inline-block',
        'width': '4rem',
        'height': '4rem',
        'border': '5px solid rgba(255,255,255)',
        'border-radius': '50%',
        'border-top-color': '#f7893c',
        'animationName': [rotateKeyframes],
        'animationDuration': '1s',
        'animationTimingFunction': 'ease-in-out',
        'animationIterationCount': 'infinite'
    }
});

export default styles;
