import {FunctionComponent, MouseEvent, useMemo, useState} from 'react';
import {Props} from './Props';
import styles from './styles';
import {css} from 'aphrodite';
import {
    DATA_SOURCE_STATUS_ACTIVE,
    DATA_SOURCE_STATUS_LOADING_DATA,
    DATA_SOURCE_STATUS_UPDATE_OUT_OF_SYNC
} from '../../../../../../../../interfaces/DataSource';
import TimeFormatted from '../../../../../../../../components/TimeFormatted';
import ButtonIcon from '../../../../../../../../components/ButtonIcon';

export const MapLayerRow: FunctionComponent<Props> = (
    {
        indented,
        mapLayer: {id, description, name, isActive, explanation},
        mapSource: {status, lastUpdateReceived},
        clickedOnLayer
    }) => {
    const [explanationVisible, setExplanationVisible] = useState<boolean>(false);

    const layerStatusIconContainerClass = useMemo(
        (): string => {
            if (!isActive) {
                return css(styles.iconContainer);
            }

            switch (status) {
                case DATA_SOURCE_STATUS_ACTIVE:
                    return css(styles.iconContainer, styles.iconContainerActive);
                case DATA_SOURCE_STATUS_LOADING_DATA:
                case DATA_SOURCE_STATUS_UPDATE_OUT_OF_SYNC:
                    return css(styles.iconContainer, styles.iconContainerLoadingData);
                default:
                    return css(styles.iconContainer);
            }
        },
        [isActive, status]
    );

    const layerStatusIconFontAwesomeIconName = useMemo(
        () => {
            if (!isActive) {
                return 'fa-toggle-off';
            }

            switch (status) {
                case 'ACTIVE':
                    return 'fa-toggle-on';
                case 'LOADING_DATA':
                    return 'fa-sync fa-spin';
                case 'UPDATE_OUT_OF_SYNC':
                    return 'fa-history';
                default:
                    return 'fa-toggle-off';
            }
        },
        [isActive, status]
    );

    const layerNameTextClass = useMemo(() => {
        if (!isActive) {
            return css(styles.mapLayerName);
        }

        switch (status) {
            case 'ACTIVE':
                return css(styles.mapLayerName, styles.mapLayerNameActive);
            case 'LOADING_DATA':
            case 'UPDATE_OUT_OF_SYNC':
                return css(styles.mapLayerName, styles.mapLayerNameLoadingData);
            default:
                return css(styles.mapLayerName);
        }
    }, [isActive, status]);

    const mapSourceOutdatedTime = useMemo(() => {
        if (status !== DATA_SOURCE_STATUS_UPDATE_OUT_OF_SYNC) {
            return undefined;
        }

        return <span className={css(styles.mapLayerDescription)}>
            <strong>Data van:</strong> <TimeFormatted dateTime={lastUpdateReceived!}/>
        </span>;
    }, [status, lastUpdateReceived]);

    const handleClickOnLayerExplanation = (event: MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setExplanationVisible(!explanationVisible);
    };

    return (
        <div className={css(styles.mapLayerRowWrapper, indented && styles.indentedRow)}>
            <div className={css(styles.mapLayerButtonsWrapper)}>
                <button
                    type="button"
                    data-cy={`layer-${id}`}
                    onClick={() => clickedOnLayer(id)}
                    className={css(styles.mapLayerRowContainer)}
                >
                    <span className={layerStatusIconContainerClass}>
                        <span className={`fas ${layerStatusIconFontAwesomeIconName}`} />
                    </span>
                    <span className={layerNameTextClass}>{name}</span>

                    {description.length > 0 && (
                        <span className={css(styles.mapLayerDescription)}>{description}</span>
                    )}

                    {mapSourceOutdatedTime}
                </button>
                {explanation && (
                    <ButtonIcon
                        iconClassName="fas fa-info-circle"
                        onClick={handleClickOnLayerExplanation}
                        aria-expanded={explanationVisible}
                        aria-label={explanationVisible ? 'Verberg toelichting' : 'Toon toelichting'}
                        aria-controls={`explanation-${id}`}
                    />
                )}
            </div>

            {explanation && explanationVisible && (
                <div className={css(styles.explanationWrapper)}>
                    <p id={`explanation-${id}`} className={css(styles.explanationBox)}>{explanation}</p>
                </div>
            )}
        </div>
    );
};
