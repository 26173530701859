import {isActionOf} from 'typesafe-actions';
import {changeCurrentRoute, layoutComponentRequestedLogoutCurrentUser} from '../../components/Layout/actions';
import {combineEpics, Epic} from 'redux-observable';
import {filter, ignoreElements, map, switchMap} from 'rxjs/operators';
import {userLogoutRequested} from '@ndw/react-keycloak-authentication';
import {router} from '../../routing/router';
import {from} from 'rxjs';

const navigateOnChangeCurrentRoute: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(changeCurrentRoute)),
        switchMap((action) => {
            const {route, options} = action.payload;
            return from(router.navigate({
                pathname: route,
                hash: location.hash,
            }, options));
        }),
        ignoreElements()
    );

const requestCloseSessionOnLogoutRequested: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(layoutComponentRequestedLogoutCurrentUser)),
        map(() => userLogoutRequested())
    );

const layoutEpics: Epic = combineEpics(
    navigateOnChangeCurrentRoute,
    requestCloseSessionOnLogoutRequested
);

export default layoutEpics;
