import React, {ReactElement} from 'react';
import AgGrid from '../../../AgGrid';
import {Props} from './Props';
import {DateCellRenderer} from '../../../AgGrid/components/DateCellRenderer';
import {handleRowClick} from '../TableService';
import {SituationFeature} from '../../../../interfaces/SituationFeature';
import {enrichedDescription} from '../../../../services/SituationFeatureService';
import {ICellRendererParams} from '@ag-grid-community/core';

class OverviewSituationAllTrafficMessage extends React.Component<Props> {

    render(): ReactElement {
        const {data, showRecordIds, suppressRowClickSelection} = this.props;
        const dataWithEnrichedDescription =
            data.map(situationFeature => (
                {
                    ...situationFeature,
                    properties:
                        {
                            ...situationFeature.properties,
                            locationDescription: enrichedDescription(situationFeature.properties)
                        }
                })) as SituationFeature[];

        return <AgGrid<SituationFeature>
            columns={[
                {
                    valueGetter: item => item.data.properties.id,
                    headerName: 'ID',
                    hide: !showRecordIds
                },
                {
                    valueGetter: item => item.data.properties.locationDescription,
                    headerName: 'Omschrijving'
                },
                {
                    valueGetter: item => item.data.properties.source,
                    headerName: 'Source'
                },
                {
                    cellRenderer: DateCellRenderer,
                    valueGetter: item => item.data.properties.versionTime,
                    headerName: 'Versie tijd'
                },
                {
                    valueGetter: item => item.data.properties.subType,
                    headerName: 'Sub type'
                },
                {
                    valueGetter: item => item.data.properties.mobilityOfObstruction,
                    headerName: 'mobilityOfObstruction'
                },
                {
                    valueGetter: item => item.data.properties.cause,
                    headerName: 'Reden'
                },
                {
                    valueGetter: item => item.data.properties.comment,
                    headerName: 'Comment'
                },
                {
                    cellRenderer: DateCellRenderer,
                    valueGetter: item => item.data.properties.overallStartTime,
                    headerName: 'Start tijd',
                    sort: 'desc'
                },
                {
                    cellRenderer: DateCellRenderer,
                    valueGetter: item => item.data.properties.overallEndTime,
                    headerName: 'Eind tijd'
                },
                {
                    cellRenderer: (columnData: ICellRendererParams) => {
                        const active = columnData.value === true;
                        return <i className={`fas ${active ? 'fa-check' : 'fa-times'}`} style={{color: active ? '#28a745' : '#dc3545'}} />;
                    },
                    valueGetter: item => item.data.properties.safetyRelatedMessage,
                    headerName: 'Veiligheidsgerelateerd bericht'
                }
            ]}
            data={dataWithEnrichedDescription}
            enableColResize={true}
            enableFilter={true}
            enableSorting={true}
            rowSelection={'single'}
            suppressScrollOnNewData={true}
            suppressRowClickSelection={suppressRowClickSelection}
            onRowClicked={(item) => handleRowClick(this.props, item)}
        />;
    }

}

export default OverviewSituationAllTrafficMessage;
