import React from 'react';
import {ThemeColorScheme} from '../themes';

export interface ThemeContentValue {
    currentColorSchemeIsDark: boolean;
    currentColorSchemeIsSuitableForColorBlindness: boolean;
    themeColorScheme: ThemeColorScheme;
}

const ThemeContext = React.createContext<ThemeContentValue>({
    currentColorSchemeIsDark: false,
    currentColorSchemeIsSuitableForColorBlindness: false,
    themeColorScheme: ThemeColorScheme.LIGHT
});

export const useThemeContext = () => React.useContext(ThemeContext);

export default ThemeContext;
