import {StyleSheet} from 'aphrodite/no-important';
import {NAV_BAR_COLORLINES_TOTAL_HEIGHT, NAV_BAR_HEIGHT} from '../../styles';

const styles = StyleSheet.create({
    wrapper: {
        'height': `calc(100% - ${NAV_BAR_HEIGHT + NAV_BAR_COLORLINES_TOTAL_HEIGHT}px)`,
        'display': 'flex',
        'flex-direction': 'column',
        'align-items': 'center',
        'padding-block-start': '5rem'
    },
    content: {
        'font-size': '1.1rem',
        'display': 'flex',
        'flex-direction': 'column',
        'line-height': '1.5',
    },
    button: {
        'align-self': 'baseline',
        'margin-block': '2rem',
    },
    details: {
        'margin-block-start': '1rem'
    }
});

export default styles;
