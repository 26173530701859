import React, {ReactElement} from 'react';
import {NOT_SUPPORTED_SCENE, SCENES} from '../../scenes';
import {viewerApplicationSceneChanged} from '../../actions';
import {AnyAction, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {Props} from './Props';

class NotSupportedScene extends React.Component<Props> {

    componentDidMount(): void {
        this.props.onSceneChanged(NOT_SUPPORTED_SCENE);
    }

    render(): ReactElement {
        return <h1>Niet ondersteund</h1>;
    }

}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    onSceneChanged: (scene: SCENES) => dispatch(viewerApplicationSceneChanged(scene))
});

export default connect(mapStateToProps, mapDispatchToProps)(NotSupportedScene);
