import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {loadBridgeHistory, loadHistoricalData} from '../../../services/ViewerApiService';
import {isActionOf} from 'typesafe-actions';
import {filter, mergeMap, map, switchMap, takeUntil} from 'rxjs/operators';
import {
    storeNewBridgeHistoricalGraphData,
    storeNewGraphData
} from '../../../scenes/MapScene/actions/reducers/activeDialogs';
import {RootState} from '../../../reducer';
import {EMPTY, interval, of} from 'rxjs';
import {VectorLayerStateTravelTime} from '../../../interfaces/VectorLayerSources/VectorLayerStateTravelTime';
import {viewerApplicationSceneChanged} from '../../../actions';
import {MAP_SCENE} from '../../../scenes';
import {
    closeGraphDialog,
    openGraphDialog
} from '../../../scenes/MapScene/actions/travelTimeDialog';
import {mapSceneOpenBridgeGraphDialog} from '../../../scenes/MapScene/actions/bridgeDialog';
import {viewerApiServiceReceivedHistoricalData} from '../../../services/ViewerApiService/actions/historicalDataData';
import {viewerApiServiceReceivedBridgeHistory} from '../../../services/ViewerApiService/actions/loadBridgeHistory';
import {PlotData} from 'plotly.js';
import {chartColors} from '../../../styles';
import {ActiveDialogVectorLayerFeatureTravelTimeGraphData} from '../../../interfaces/ActiveDialog';
import {DIALOG_GRAPHS} from '../../../scenes/MapScene/actions';

const loadBridgeDataOnOpenBridgeGraphDialogEffect: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(mapSceneOpenBridgeGraphDialog)),
        switchMap(({payload: {vild, risIndex}}) => loadBridgeHistory(risIndex, vild))
    );

const loadHistoricalDataOnOpenGraphDialogEffect: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(openGraphDialog)),
        switchMap((action) => loadHistoricalData(
            action.payload.dialog,
            action.payload.mapSourceID,
            action.payload.featureID,
            action.payload.locationID,
            action.payload.timestamp
        ))
    );

const saveBridgeGraphDataOnReceivedBridgeHistoryEffect: Epic = (action$) =>
    action$.pipe(
        filter(isActionOf(viewerApiServiceReceivedBridgeHistory)),
        map(({payload: {risIndex, vild, bridgeHistoryData}}) =>
            storeNewBridgeHistoricalGraphData(risIndex, vild, bridgeHistoryData))
    );

const saveGraphDataOnReceivedHistoricalDataEffect: Epic = (action$, state$: StateObservable<RootState>) =>
    action$.pipe(
        filter(isActionOf(viewerApiServiceReceivedHistoricalData)),
        map((action) => {
            const {data, dataType, featureID, mapSourceID} = action.payload;

            switch (dataType) {
                case 'travelTime':
                    const mapSource = state$.value.mapScene.data.sources[mapSourceID];
                    if (mapSource.dataLoadMethod !== 'VectorTiles') {
                        return EMPTY;
                    }
                    const travelTimeCurrentData: VectorLayerStateTravelTime | undefined = mapSource.layerMapData![featureID] as VectorLayerStateTravelTime | undefined;

                    if (!travelTimeCurrentData) {
                        return EMPTY;
                    }

                    const dataSetTravelTimeActual: Partial<PlotData> = {
                        x: [],
                        y: [],
                        name: 'Actueel',
                        mode: 'lines+markers',
                        marker: {
                            color: chartColors[0]
                        },
                        line: {
                            color: chartColors[0]
                        }
                    };
                    const dataSetTravelTimeFreeFlow: Partial<PlotData> = {
                        x: [],
                        y: [],
                        name: 'Freeflow',
                        mode: 'lines+markers',
                        marker: {
                            color: chartColors[1]
                        },
                        line: {
                            color: chartColors[1]
                        }
                    };

                    const dataSetVelocityActual: Partial<PlotData> = {
                        x: [],
                        y: [],
                        name: 'Actueel',
                        mode: 'lines+markers',
                        marker: {
                            color: chartColors[0]
                        },
                        line: {
                            color: chartColors[0]
                        }
                    };
                    const dataSetVelocityFreeFlow: Partial<PlotData> = {
                        x: [],
                        y: [],
                        name: 'Freeflow',
                        mode: 'lines+markers',
                        marker: {
                            color: chartColors[1]
                        },
                        line: {
                            color: chartColors[1]
                        }
                    };

                    const dataCastTravelTime: { [key: string]: { travelTime: number, velocity: number } } = data;

                    for(const dataKey in dataCastTravelTime) {
                        if (!dataCastTravelTime.hasOwnProperty(dataKey)) {
                            continue;
                        }

                        const value = dataCastTravelTime[dataKey];

                        (dataSetTravelTimeActual.y as number[]).push(value.travelTime);
                        (dataSetTravelTimeActual.x as string[]).push(dataKey);
                        (dataSetVelocityActual.y as number[]).push(value.velocity);
                        (dataSetVelocityActual.x as string[]).push(dataKey);
                        (dataSetTravelTimeFreeFlow.y as number[]).push(travelTimeCurrentData.freeFlow);
                        (dataSetTravelTimeFreeFlow.x as string[]).push(dataKey);
                        (dataSetVelocityFreeFlow.y as number[]).push(travelTimeCurrentData.freeFlowSpeed);
                        (dataSetVelocityFreeFlow.x as string[]).push(dataKey);
                    }

                    const graphData: ActiveDialogVectorLayerFeatureTravelTimeGraphData = {
                        travelTime: [dataSetTravelTimeActual, dataSetTravelTimeFreeFlow],
                        velocity: [dataSetVelocityActual, dataSetVelocityFreeFlow],
                        timeStamp: Date.now()
                    };

                    return storeNewGraphData(mapSourceID, featureID, graphData);
                case 'flowSpeed':
                    const dataCastFlowSpeed: {
                        [key: string]: { [key: string]: { [key: string]: { flow: number, speed: number } } }
                    } =
                        data;
                    const flowDataSets: { [key: string]: Partial<PlotData> } = {};
                    const speedDataSets: { [key: string]: Partial<PlotData> } = {};
                    let indexCounter: number = 0;

                    for(const dataDate in dataCastFlowSpeed) {
                        if (!dataCastFlowSpeed.hasOwnProperty(dataDate)) {
                            continue;
                        }

                        const dateData = dataCastFlowSpeed[dataDate];

                        for(const laneName in dateData) {
                            if (!dateData.hasOwnProperty(laneName)) {
                                continue;
                            }

                            const laneData = dateData[laneName];

                            for(const laneCategoryName in laneData) {
                                if (!laneData.hasOwnProperty(laneCategoryName)) {
                                    continue;
                                }
                                const lookupName = `${laneName}-${laneCategoryName}`;

                                if (!flowDataSets.hasOwnProperty(lookupName)) {
                                    flowDataSets[lookupName] = {
                                        x: [],
                                        y: [],
                                        name: `${laneCategoryName} - ${laneName}`,
                                        mode: 'lines+markers',
                                        marker: {
                                            color: chartColors[indexCounter]
                                        },
                                        line: {
                                            color: chartColors[indexCounter]
                                        }
                                    };
                                }
                                if (!speedDataSets.hasOwnProperty(lookupName)) {
                                    speedDataSets[lookupName] = {
                                        x: [],
                                        y: [],
                                        name: `${laneCategoryName} - ${laneName}`,
                                        mode: 'lines+markers',
                                        marker: {
                                            color: chartColors[indexCounter]
                                        },
                                        line: {
                                            color: chartColors[indexCounter]
                                        }
                                    };
                                }
                                indexCounter++;

                                (flowDataSets[lookupName].x as string[]).push(dataDate);
                                (flowDataSets[lookupName].y as number[]).push(laneData[laneCategoryName].flow);
                                (speedDataSets[lookupName].x as string[]).push(dataDate);
                                (speedDataSets[lookupName].y as number[]).push(laneData[laneCategoryName].speed);
                            }
                        }
                    }

                    return storeNewGraphData('flowSpeed', featureID, {
                        speed: Object.values(speedDataSets),
                        flow: Object.values(flowDataSets),
                        timeStamp: Date.now()
                    });
            }
        })
    );

const startReloadingOnOpenDialogEffect: Epic = (action$) => {
    let openGraphs: {
        [key: string]: {
            mapSourceID: string;
            featureID: number | string;
            locationID: string;
            dialog: DIALOG_GRAPHS;
            timestamp: number;
        }
    } = {};

    action$
        .pipe(
            filter(isActionOf(openGraphDialog))
        )
        .subscribe((action) => {
            openGraphs[`${action.payload.mapSourceID}-${action.payload.featureID}`] = action.payload;
        });

    action$
        .pipe(filter(isActionOf(closeGraphDialog)))
        .subscribe((action) => {
            delete openGraphs[`${action.payload.mapSourceID}-${action.payload.featureID}`];
        });

    action$
        .pipe(
            filter(isActionOf(viewerApplicationSceneChanged)),
            filter((action) => action.payload.scene !== MAP_SCENE)
        )
        .subscribe(() => {
            openGraphs = {};
        });

    return action$.pipe(
        filter(isActionOf(viewerApplicationSceneChanged)),
        filter((action) => action.payload.scene === MAP_SCENE),
        switchMap(() => interval(60000)
            .pipe(
                takeUntil(action$.pipe(
                    filter(isActionOf(viewerApplicationSceneChanged)),
                    filter((action) => action.payload.scene !== MAP_SCENE)
                )),
                filter(() => Object.keys(openGraphs).length > 0),
                mergeMap(() => {
                    return of(...Object.values(openGraphs))
                        .pipe(
                            switchMap((action) => loadHistoricalData(
                                action.dialog,
                                action.mapSourceID,
                                action.featureID,
                                action.locationID,
                                action.timestamp
                            ))
                        );
                })
            ))
    );
};

const graphEpicEffects: Epic = combineEpics(
    loadBridgeDataOnOpenBridgeGraphDialogEffect,
    loadHistoricalDataOnOpenGraphDialogEffect,
    saveBridgeGraphDataOnReceivedBridgeHistoryEffect,
    saveGraphDataOnReceivedHistoricalDataEffect,
    startReloadingOnOpenDialogEffect
);

export default graphEpicEffects;
