/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GeoJsonSnapshotEvent } from '../models/GeoJsonSnapshotEvent';
import type { VectorSnapshotEvent } from '../models/VectorSnapshotEvent';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SnapshotControllerService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param id
     * @returns any Snapshot
     * @throws ApiError
     */
    public getSnapshotById(
        id: string,
    ): CancelablePromise<(GeoJsonSnapshotEvent | VectorSnapshotEvent)> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/snapshots/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Snapshot not found`,
            },
        });
    }
}
