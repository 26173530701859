import React, {ReactElement} from 'react';
import {Props} from './Props';
import {State, TravelTimeTab} from './State';
import TabBar from '../../../../../../components/TabBar';
import {TabBarItem} from '../../../../../../components/TabBar/Props';
import StyledButton from '../../../../../../components/StyledButton';
import DateTimeFormatted from '../../../../../../components/DateTimeFormatted';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import SimpleGraph from '../../../../../../components/SimpleGraph';

class DialogTravelTime extends React.Component<Props, State> {

    tabBarItems: TabBarItem<TravelTimeTab>[];
    chartColors: { actual: string, freeFlow: string };
    locationID: string | null;

    constructor(props: Props) {
        super(props);

        this.state = {
            currentTab: TravelTimeTab.travelTime,
            tabTimestamp: 3600
        };

        this.tabBarItems = [
            {
                id: TravelTimeTab.travelTime,
                name: 'Reistijd'
            },
            {
                id: TravelTimeTab.velocity,
                name: 'Snelheid'
            }
        ];

        this.chartColors = {
            actual: '#ac183d',
            freeFlow: '#3cb44b'
        };

        this.locationID = null;
    }

    componentDidMount(): void {
        const {properties} = this.props;

        try {
            const parsed = JSON.parse(properties.locations);
            this.locationID = parsed[0];
            this.triggerOpenDialog();
        } catch (e) {
            return;
        }
    }

    componentWillUnmount(): void {
        const {properties, mapSourceID, closeDialog} = this.props;
        closeDialog(mapSourceID, properties.id);
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const {properties} = this.props;

        if ((properties.locations && !prevProps) || (properties.locations !== prevProps.properties.locations)) {
            try {
                const parsed = JSON.parse(properties.locations);
                this.locationID = parsed[0];
            } catch (e) {
                return;
            }
        }
    }

    changeCurrentActiveTab = (currentTab: TravelTimeTab): void => this.setState({currentTab});

    changeTimestamp = (): void => {
        this.setState({
            tabTimestamp: this.state.tabTimestamp === 3600 ? 86400 : 3600
        }, () => this.triggerOpenDialog());
    };

    triggerOpenDialog = (): void => {
        const {properties, mapSourceID, openGraphDialog} = this.props;
        const {tabTimestamp} = this.state;

        openGraphDialog(
            mapSourceID,
            properties.id,
            this.locationID!,
            'travelTime',
            tabTimestamp
        );
    };

    render(): ReactElement {
        const {properties, featureState, graphData, showRecordIds} = this.props;
        const {currentTab, tabTimestamp} = this.state;

        const {name, length} = properties;

        return <>
            <table>
                <tbody>
                    {showRecordIds && <tr>
                        <th>ID</th>
                        <td>{this.getTrajectoryID()}</td>
                    </tr>}
                    <tr>
                        <th>Naam</th>
                        <td>{name}</td>
                    </tr>
                    <tr>
                        <th>Lengte in meters</th>
                        <td>{length}</td>
                    </tr>
                    <tr>
                        <th>Tijdstempel</th>
                        <td>
                            <DateTimeFormatted dateTime={featureState.timestamp}/>
                        </td>
                    </tr>
                </tbody>
            </table>

            <StyledButton
                onPress={() => this.openInDexterAvg()}
                title={'Historische reistijden en  snelheden bekijken in Dexter'}
            />

            <StyledButton
                onPress={() => this.openInDexterFcd()}
                title={'Historische reistijden en snelheden bekijken in FCD explorer'}
            />

            <br/>

            <TabBar<TravelTimeTab>
                tabBarItems={this.tabBarItems}
                activeId={currentTab}
                onClickedOnTab={(id) => this.changeCurrentActiveTab(id)}
            />

            <div className={css(styles.tabContent)}>
                <div style={{display: currentTab === TravelTimeTab.travelTime ? 'block' : 'none'}}>
                    <table className={'last-column-right'}>
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Seconden</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Actueel</td>
                                <td
                                    className={css(styles.tableTravelTimeVelocityRightBorder)}
                                    style={{borderRightColor: this.chartColors.actual}}
                                >
                                    {featureState.travelTime}
                                </td>
                            </tr>
                            <tr>
                                <td>Free flow</td>
                                <td
                                    className={css(styles.tableTravelTimeVelocityRightBorder)}
                                    style={{borderRightColor: this.chartColors.freeFlow}}
                                >
                                    {featureState.freeFlow}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <SimpleGraph
                        data={graphData?.travelTime || []}
                        title={'Voertuigen overzicht'}
                        width={357}
                        revision={graphData?.timeStamp || 0}
                    />

                    <br/>
                    <p>Weergeef data in de grafiek van de afgelopen:</p>
                    <StyledButton
                        onPress={this.changeTimestamp}
                        title={tabTimestamp === 3600 ? '1-uur' : '24-uur'}
                    />
                </div>

                <div style={{display: currentTab === TravelTimeTab.velocity ? 'block' : 'none'}}>
                    <table className={'last-column-right'}>
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>KM/h</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Actueel</td>
                                <td
                                    className={css(styles.tableTravelTimeVelocityRightBorder)}
                                    style={{borderRightColor: this.chartColors.actual}}
                                >
                                    {featureState.velocity}
                                </td>
                            </tr>
                            <tr>
                                <td>Free flow</td>
                                <td
                                    className={css(styles.tableTravelTimeVelocityRightBorder)}
                                    style={{borderRightColor: this.chartColors.freeFlow}}
                                >
                                    {featureState.freeFlowSpeed}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <SimpleGraph
                        data={graphData?.velocity || []}
                        title={'Voertuigen overzicht'}
                        width={357}
                        revision={graphData?.timeStamp || 0}
                    />

                    <br/>
                    <p>Weergeef data in de grafiek van de afgelopen:</p>
                    <StyledButton
                        onPress={this.changeTimestamp}
                        title={tabTimestamp === 3600 ? '1-uur' : '24-uur'}
                    />
                </div>
            </div>
        </>;
    }

    private getTrajectoryID(): string {
        const {properties} = this.props;

        try {
            const parsed = JSON.parse(properties.locations);
            return parsed[0];
        } catch (e) {
            return properties.id.toString();
        }
    }

    private openInDexterAvg(): void {
        window.open(`https://dexter.ndw.nu/deeplink/explorer/avg?id=${this.getTrajectoryID()}&type=trajectory`, '_blank')?.focus();
    }

    private openInDexterFcd(): void {
        window.open(`https://dexter.ndw.nu/deeplink/explorer/fcd?id=${this.getTrajectoryID()}`, '_blank')?.focus();
    }
}

export default DialogTravelTime;
