import {StyleSheet} from 'aphrodite';


const styles = StyleSheet.create({
    container: {
        width: 'calc(100% - 2px)'
    },
    input: {
        '::placeholder': {
            fontStyle: 'italic'
        },
        ':disabled': {
            color: '#ccc'
        },
        'border': '1px solid #0a244d',
        'borderRadius': '.25rem',
        'color': '#495057',
        'padding': '.75rem',
        'width': 'calc(100% - .75rem - .75rem)'
    }
});

export default styles;
