import React, {ReactElement} from 'react';
import DraggableDialog from '../../../../components/DraggableDialog';
import {
    ActiveDialogSelectFeature,
    ActiveDialogVectorLayerFeatureTravelTime,
    DIALOG_INTERFACES,
    DIALOG_INTERFACES_GEO_JSON,
    DIALOG_INTERFACES_VECTOR_LAYER,
    DialogTypes,
    GeoJsonSubTypes,
    VectorLayerSubTypes
} from '../../../../interfaces/ActiveDialog';
import {GeoJsonPropertiesBridge} from '../../../../interfaces/GeoJsonSources/GeoJsonPropertiesBridge';
import {GeoJsonPropertiesDrip} from '../../../../interfaces/GeoJsonSources/GeoJsonPropertiesDrip';
import {GeoJsonPropertiesFlowSpeed} from '../../../../interfaces/GeoJsonSources/GeoJsonPropertiesFlowSpeed';
import {GeoJsonPropertiesRoadWork} from '../../../../interfaces/GeoJsonSources/GeoJsonPropertiesRoadWork';
import {GeoJsonPropertiesSituation} from '../../../../interfaces/GeoJsonSources/GeoJsonPropertiesSituation';
import {GeoJsonPropertiesWaze} from '../../../../interfaces/GeoJsonSources/GeoJsonPropertiesWaze';
import {VectorLayerPropertiesFcdClass} from '../../../../interfaces/VectorLayerSources/VectorLayerPropertiesFcdClass';
import {
    VectorLayerPropertiesTravelTime
} from '../../../../interfaces/VectorLayerSources/VectorLayerPropertiesTravelTime';
import {WAZE_ALERT_ITEM_SUB_TYPES} from '../../../../interfaces/WazeAlertItemStatus';
import DialogBridge from './components/DialogBridge';
import DialogDfine from './components/DialogDfine';
import DialogDrip from './components/DialogDrip';
import DialogFcdClass from './components/DialogFcdClass';
import DialogFlowSpeed from './components/DialogFlowSpeed';
import DialogHere from './components/DialogHere';
import DialogMSI from './components/DialogMSI';
import DialogRoadworkActual from './components/DialogRoadworkActual';
import DialogRoadWorkPlanned from './components/DialogRoadWorkPlanned';
import DialogRushHourLane from './components/DialogRushHourLane';
import DialogSelectFeature from './components/DialogSelectFeature';
import DialogSituationOnda from './components/DialogSituationOnda';
import DialogTrafficMessage from './components/DialogTrafficMessage';
import DialogTravelTime from './components/DialogTravelTime';
import DialogWaze from './components/DialogWaze';
import DialogWazeAlertIncident from './components/DialogWazeAlertIncident';
import {Props} from './Props';
import {DialogIncidentManagement} from './components/DialogIncidentManagement';
import {
    GeoJsonPropertiesIncidentManagement
} from '../../../../interfaces/GeoJsonSources/GeoJsonPropertiesIncidentManagement';
import {
    GeoJsonPropertiesRoadWorkPlannedOtm
} from '../../../../interfaces/GeoJsonSources/GeoJsonPropertiesRoadWorkPlannedOtm';
import {DialogRoadWorkPlannedOtm} from './components/DialogRoadWorkPlannedOtm';
import {GeoJsonPropertiesFd} from '../../../../interfaces/GeoJsonSources/GeoJsonPropertiesFd';
import {DialogFd} from './components/DialogFd';
import {GeoJsonPropertiesSchoolzone} from '../../../../interfaces/GeoJsonSources/GeoJsonPropertiesSchoolzone';
import {DialogSchoolZones} from './components/DialogSchoolZones';
import {DialogueHiddenContent} from './components/DialogueHiddenContent';
import {descriptiveTime, enrichedDescription} from '../../../../services/SituationFeatureService';
import DialogSituationVehicleObstructionEmergencyVehicle
    from './components/DialogSituationVehicleObstructionEmergencyVehicle';

class Dialogs extends React.Component<Props> {

    closeDialog = (id: string | number): void => this.props.requestCloseDialog(id);

    changePinStatus = (index: number, newStatus: boolean): void => {
        this.props.changeDialogPinStatus(index, newStatus);
    };

    clickedOnDialogTitle = (index: number): void => {
        this.props.focusOnDialog(index);
    };

    render(): ReactElement {
        const {activeDialogs} = this.props;

        return <>
            {activeDialogs.map((item: DIALOG_INTERFACES, index: number) => this.renderDialog(item, index))}
        </>;
    }

    private renderDialog = (dialog: DIALOG_INTERFACES, index: number) => {
        switch (dialog.type) {
            case DialogTypes.SELECT_FEATURES:
                return this.renderDialogContentSelectFeature(dialog, index);
            case DialogTypes.GEO_JSON:
                return this.renderDialogGeoJSONFeature(dialog, index);
            case DialogTypes.VECTOR_LAYER:
                return this.renderDialogVectorTileFeature(dialog, index);
        }

        return <React.Fragment key={index}/>;
    };

    private renderDialogContentSelectFeature = (item: ActiveDialogSelectFeature, index: number) => {
        const {mapLayers, clickedFeatures} = this.props;

        return <DraggableDialog
            key={item.id}
            pinned={item.pinned}
            startPointX={item.startPosition.x}
            startPointY={item.startPosition.y}
            title={'Kies welke popup u wilt openen'}
            zIndex={item.zIndex}
            focusOnDialog={() => this.clickedOnDialogTitle(index)}
            requestDialogClose={() => this.closeDialog(item.id)}
            requestPinStatusChange={() => this.changePinStatus(index, !item.pinned)}
        >
            <p>De positie die u aanklikt heeft meerdere popups die beschikbaar zijn.
                Kies a.u.b. welke popup u wilt openen uit onderstaande lijst:</p>

            <DialogSelectFeature
                features={item.features}
                clickedFeatures={clickedFeatures}
                mapLayers={mapLayers}
            />
        </DraggableDialog>;
    };

    private renderDialogGeoJSONFeature = (item: DIALOG_INTERFACES_GEO_JSON, index: number) => {
        return <DraggableDialog
            key={`${item.dataSource}-${item.id}`}
            startPointX={item.startPosition.x}
            startPointY={item.startPosition.y}
            pinned={item.pinned}
            title={this.titleForGeoJsonAndVectorPopup(item)}
            zIndex={item.zIndex}
            focusOnDialog={() => this.clickedOnDialogTitle(index)}
            requestDialogClose={() => this.closeDialog(item.id)}
            requestPinStatusChange={() => this.changePinStatus(index, !item.pinned)}
        >
            {this.renderGeoJSONPopup(item)}
        </DraggableDialog>;
    };

    private renderDialogVectorTileFeature = (item: DIALOG_INTERFACES_VECTOR_LAYER, index: number) => {
        return <DraggableDialog
            key={`${item.dataSource}-${item.id}`}
            startPointX={item.startPosition.x}
            startPointY={item.startPosition.y}
            pinned={item.pinned}
            title={this.titleForGeoJsonAndVectorPopup(item)}
            zIndex={item.zIndex}
            focusOnDialog={() => this.clickedOnDialogTitle(index)}
            requestDialogClose={() => this.closeDialog(item.id)}
            requestPinStatusChange={() => this.changePinStatus(index, !item.pinned)}
        >
            {this.renderVectorTilePopup(item)}
        </DraggableDialog>;
    };

    private renderGeoJSONPopup = (item: DIALOG_INTERFACES_GEO_JSON) => {
        const props = this.props;
        switch (item.subType) {
            case GeoJsonSubTypes.FLOW_SPEED:
                return <DialogFlowSpeed
                    properties={item.currentProperties as never}
                    graphData={item.graphs}
                    showRecordIds={props.showRecordIds}
                    openGraphDialog={props.openGraphDialog}
                />;
            case GeoJsonSubTypes.BRIDGE:
                return <DialogBridge
                    bridgeHistoryData={item.bridgeHistory}
                    properties={item.currentProperties as never}
                    showRecordIds={props.showRecordIds}
                    onOpenDialog={props.openBridgeGraphDialog}
                />;
            case GeoJsonSubTypes.WAZE_ALERT_INCIDENT:
                return <DialogWazeAlertIncident
                    currentProperties={item.currentProperties}
                    hectometrePost={item.hectometrePost}
                    isOpen={item.isOpen}
                    status={item.status || item.currentProperties.status}
                    subType={item.newSubType || item.currentProperties.subType as WAZE_ALERT_ITEM_SUB_TYPES}
                    dataHasBeenLoaded={item.hasLoadedData}
                    dataLoadingHasFailed={item.loadingDataFailed}
                    lastChangeTimestamp={item.lastChangeTimestamp}
                    lastSaveTimestamp={item.lastSaveTimestamp}
                    savingStatus={item.savingStatus}
                    timeRemainingBeforeAutomaticClosure={item.timeRemainingBeforeAutomaticClosure}
                    showRecordIds={props.showRecordIds}
                    changeWazeAlertData={props.changeWazeAlertData}
                    openWazeAlertDialog={props.openWazeAlertDialog}
                    closeWazeAlertDialog={props.closeWazeAlertDialog}
                    saveWazeAlertData={props.saveWazeAlertData}
                />;
            case GeoJsonSubTypes.GENERAL:
                switch (item.mapLayer) {
                    case 'drips':
                        return <DialogDrip
                            currentProperties={item.currentProperties as never}
                            showRecordIds={props.showRecordIds}
                        />;
                    case 'msi':
                        return <DialogMSI
                            currentProperties={item.currentProperties as never}
                            showRecordIds={props.showRecordIds}
                        />;
                    case 'onda':
                        const dialogSituationOnda = <DialogSituationOnda
                            properties={item.currentProperties as GeoJsonPropertiesSituation}
                            showRecordIds={props.showRecordIds}
                        />;
                        return <DialogueHiddenContent
                            Dialogue={dialogSituationOnda}
                            subTitle={descriptiveTime(item.currentProperties as GeoJsonPropertiesSituation)}
                            description={enrichedDescription(item.currentProperties as GeoJsonPropertiesSituation)}
                        />;
                    case 'vehicleObstructionEmergencyVehicle':
                        return <DialogSituationVehicleObstructionEmergencyVehicle
                            properties={item.currentProperties as never}
                            showRecordIds={props.showRecordIds}
                        />;
                    case 'here_all':
                    case 'here':
                        return <DialogHere
                            currentProperties={item.currentProperties as never}
                            showRecordIds={props.showRecordIds}
                        />;
                    case 'trafficMessageOtis':
                    case 'trafficMessageFlisvis':
                        const dialogueTrafficMessage = <DialogTrafficMessage
                            properties={item.currentProperties as never}
                            showRecordIds={props.showRecordIds}
                        />;
                        return <DialogueHiddenContent
                            Dialogue={dialogueTrafficMessage}
                            subTitle={descriptiveTime(item.currentProperties as GeoJsonPropertiesSituation)}
                            description={enrichedDescription(item.currentProperties as GeoJsonPropertiesSituation)}
                        />;
                    case 'roadWorkActual':
                        const dialogueRoadWorkActual = <DialogRoadworkActual
                            properties={item.currentProperties as never}
                            showRecordIds={props.showRecordIds}
                        />;
                        return <DialogueHiddenContent
                            Dialogue={dialogueRoadWorkActual}
                            subTitle={descriptiveTime(item.currentProperties as GeoJsonPropertiesSituation)}
                            description={enrichedDescription(item.currentProperties as GeoJsonPropertiesSituation)}
                        />;
                    case 'roadWorkPlanned':
                        return <DialogRoadWorkPlanned
                            properties={item.currentProperties as never}
                            showRecordIds={props.showRecordIds}
                        />;
                    case 'roadWorkPlannedOtm':
                        return <DialogRoadWorkPlannedOtm
                            properties={item.currentProperties as never}
                            showRecordIds={props.showRecordIds}
                        />;
                    case 'rushHourLane':
                        const dialogueRushHourLane = <DialogRushHourLane
                            properties={item.currentProperties as GeoJsonPropertiesSituation}
                            showRecordIds={props.showRecordIds}
                        />;
                        return <DialogueHiddenContent
                            Dialogue={dialogueRushHourLane}
                            subTitle={descriptiveTime(item.currentProperties as GeoJsonPropertiesSituation)}
                            description={enrichedDescription(item.currentProperties as GeoJsonPropertiesSituation)}
                        />;
                    case 'wazeAlertsOther':
                    case 'wazeIrregularity':
                    case 'wazeJam':
                        return <DialogWaze
                            currentProperties={item.currentProperties as never}
                            showRecordIds={props.showRecordIds}
                        />;
                    case 'dfine':
                        return <DialogDfine
                            properties={item.currentProperties as never}
                            showRecordIds={props.showRecordIds}
                        />;
                    case 'im':
                        return <DialogIncidentManagement
                            properties={item.currentProperties as never}
                            showRecordIds={props.showRecordIds}
                        />;
                    case 'fd':
                        return <DialogFd
                            properties={item.currentProperties as never}
                            showRecordIds={props.showRecordIds}
                        />;
                    case'schoolzones':
                        return <DialogSchoolZones
                            properties={item.currentProperties as never}
                            showRecordIds={props.showRecordIds}
                        />;
                }
        }
        return <>
            {/*If no dialog template is known, display all properties for this element in simple json format. */}
            {JSON.stringify(item.currentProperties, null, 2)}
        </>;
    };

    private renderVectorTilePopup = (item: DIALOG_INTERFACES_VECTOR_LAYER) => {
        const {showRecordIds} = this.props;

        switch (item.subType) {
            case VectorLayerSubTypes.TRAVEL_TIME_FCD:
            case VectorLayerSubTypes.TRAVEL_TIME_OTHER:
            case VectorLayerSubTypes.TRAVEL_TIME_DRIPS:
                return this.renderVectorLayerTravelTimePopup(item);
            case VectorLayerSubTypes.GENERAL:
                switch (item.mapLayer) {
                    case 'fcdClass':
                        return <DialogFcdClass
                            properties={item.currentProperties as never}
                            state={item.currentState as never}
                            showRecordIds={showRecordIds}
                        />;
                }
        }

        return <React.Fragment/>;
    };

    private renderVectorLayerTravelTimePopup = (item: ActiveDialogVectorLayerFeatureTravelTime) => {
        const {closeDialog: closeDialogProp, openGraphDialog, showRecordIds} = this.props;

        return <DialogTravelTime
            properties={item.currentProperties as never}
            featureState={item.currentState as never}
            mapSourceID={item.dataSource}
            graphData={item.graphs}
            showRecordIds={showRecordIds}
            closeDialog={closeDialogProp}
            openGraphDialog={openGraphDialog}
        />;
    };

    private titleForGeoJsonAndVectorPopup = (item: DIALOG_INTERFACES_GEO_JSON | DIALOG_INTERFACES_VECTOR_LAYER) => {
        switch (item.mapLayer) {
            case 'im':
                const propertiesIm = item.currentProperties as GeoJsonPropertiesIncidentManagement;
                return `IM Geclusterd - ${propertiesIm.id}`;
            case 'bridge':
                const propertiesBridge = item.currentProperties as GeoJsonPropertiesBridge;
                return `Brug ${propertiesBridge.name || propertiesBridge.id}`;
            case 'drips':
                const propertiesDrip = item.currentProperties as GeoJsonPropertiesDrip;
                return `DRIP beeldstand ${propertiesDrip.name}`;
            case 'fcdClass':
                const propertiesFcdClass = item.currentProperties as VectorLayerPropertiesFcdClass;
                return `Snelheid o.b.v. FCD ${propertiesFcdClass.id}`;
            case 'flowSpeed':
                const propertiesFlowSpeed = item.currentProperties as GeoJsonPropertiesFlowSpeed;
                return `Lus ${propertiesFlowSpeed.name || propertiesFlowSpeed.id}`;
            case 'here_all':
            case 'here':
                return 'Sensordata (L2)';
            case 'dfine':
                return 'Sensordata (L3)';
            case 'msi':
                return 'MSI beeldstand';
            case 'vehicleObstructionEmergencyVehicle':
                const propertiesVoev = item.currentProperties as GeoJsonPropertiesSituation;
                return `Botsabsorber/Pijlwagen ${propertiesVoev.id}`;
            case 'onda':
                const propertiesOnda = item.currentProperties as GeoJsonPropertiesSituation;
                if (propertiesOnda.primaryLocationDescription) {
                    return `Berging op ${propertiesOnda.primaryLocationDescription} (${propertiesOnda.locationDescription})`;
                } else if (propertiesOnda.secondaryLocationDescription) {
                    return `Berging op ${propertiesOnda.secondaryLocationDescription} (${propertiesOnda.locationDescription})`;
                } else {
                    return `Berging met ID ${propertiesOnda.id}`;
                }
            case 'roadWorkActual':
                const propertiesRoadWorkActual = item.currentProperties as GeoJsonPropertiesSituation;
                return `Actuele wegwerkzaamheden ${propertiesRoadWorkActual.id}`;
            case 'roadWorkPlanned':
                const propertiesRoadWorkPlanned = item.currentProperties as GeoJsonPropertiesRoadWork;
                return `Geplande wegwerkzaamheden ${propertiesRoadWorkPlanned.id}`;
            case 'rushHourLane':
                const propertiesRushHourLane = item.currentProperties as GeoJsonPropertiesSituation;
                return `Spitsstrook geopend ${propertiesRushHourLane.locationDescription}`;
            case 'trafficMessageOtis':
            case 'trafficMessageFlisvis':
                const propertiesTrafficMessage = item.currentProperties as GeoJsonPropertiesSituation;
                return `RWS Verkeersbericht op ${propertiesTrafficMessage.locationDescription}`;
            case 'travelTimeFcd':
            case 'travelTimeOther':
            case 'travelTimeDrips':
                const propertiesTravelTime = item.currentProperties as VectorLayerPropertiesTravelTime;
                try {
                    const parsed = JSON.parse(propertiesTravelTime.locations);
                    return parsed[0];
                } catch (e) {
                    return item.id;
                }
            case 'wazeAlerts':
            case 'wazeAlertsOther':
                const propertiesWazeAlerts = item.currentProperties as GeoJsonPropertiesWaze;
                return `Waze incident ${propertiesWazeAlerts.street} ${propertiesWazeAlerts.city}`;
            case 'wazeIrregularity':
                const propertiesWazeIrregularity = item.currentProperties as GeoJsonPropertiesWaze;
                return `Waze onregelmatigheid ${propertiesWazeIrregularity.street} ${propertiesWazeIrregularity.city}`;
            case 'wazeJam':
                const propertiesWazeJam = item.currentProperties as GeoJsonPropertiesWaze;
                return `Waze file ${propertiesWazeJam.street} ${propertiesWazeJam.city}`;
            case 'roadWorkPlannedOtm':
                const propertiesRoadWorkOtm = item.currentProperties as GeoJsonPropertiesRoadWorkPlannedOtm;
                return `Geplande wegwerkzaamheden (OTM) - ${propertiesRoadWorkOtm.id}`;
            case 'fd':
                const propertiesFd = item.currentProperties as GeoJsonPropertiesFd;
                return `Ongevalideerde File - ${propertiesFd.headLocation} naar ${propertiesFd.tailLocation}`;
            case 'schoolzones':
                const propertiesSchoolzone = item.currentProperties as GeoJsonPropertiesSchoolzone;
                return `Schoolzone ${propertiesSchoolzone.name}`;
            default:
                return `${item.mapLayer} - ${item.currentProperties!.id}`;
        }
    };
}

export default Dialogs;
