import {applyMiddleware, compose, createStore} from 'redux';
import {createEpicMiddleware} from 'redux-observable';
import rootReducer from './reducer';
import rootEpics from './epics';
import {viewerApplicationBooted} from './actions';
import {composeWithDevTools} from 'redux-devtools-extension';
import {createReduxEnhancer} from '@sentry/react';

const sentryReduxEnhancer = createReduxEnhancer({
    actionTransformer: (action) => {
        if (action.type === '@NDW/FRONTEND_KEYCLOAK_AUTHENTICATION/USER_PROFILE_LOADED') {
            // make sure personal data isn't sent to Sentry
            const userProfile = {
                id: action.payload.userProfile.id
            };

            return {
                ...action,
                payload: {
                    userProfile
                }
            };
        }

        return action;
    },
});

const reduxDevToolsEnabled = true;
const composeEnhancers = reduxDevToolsEnabled ? composeWithDevTools({
    name: 'NDW Viewer'
}) : compose;

const epicMiddleware = createEpicMiddleware();

export const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(
            epicMiddleware
        ),
        sentryReduxEnhancer
    )
);

epicMiddleware.run(rootEpics);
store.dispatch(viewerApplicationBooted());
