import React from 'react';
import {Props} from './Props';
import {timeToMessageAgeString} from '../../services/Utilities';

const MessageAgeInMinutes: React.FunctionComponent<Props> = ({time, seconds}) => {
    return <>
        {timeToMessageAgeString(time, seconds || false)}
    </>;
};

export default MessageAgeInMinutes;
