import React, {useCallback, useContext, useRef, useState} from 'react';
import Draggable from 'react-draggable';
import {colorBlindnessStyles, darkStyles, styles} from './styles';
import {css} from 'aphrodite/no-important';
import {Props} from './Props';
import {useResizeDetector} from 'react-resize-detector';
import ThemeContext from '../../contexts/ThemeContext';

const MAP_PADDING_HORIZONTAL = 20;
const MAP_PADDING_VERTICAL = 56;
const MINIMUM_TOP_OFFSET = 10;

const DraggableDialog: React.FunctionComponent<Props> = ({
    startPointX,
    startPointY,
    zIndex,
    children,
    focusOnDialog,
    title,
    pinned,
    requestPinStatusChange,
    requestDialogClose
}) => {
    const themeContext = useContext(ThemeContext);

    const containerRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLHeadingElement>(null);
    const dialogContentRef = useRef<HTMLDivElement>(null);

    const [leftOffset, setLeftOffset] = useState<number | undefined>(undefined);
    const [topOffset, setTopOffset] = useState<number | undefined>(undefined);
    const [height, setHeight] = useState<number | undefined>(undefined);

    const onResize = useCallback(
        (width?: number, height?: number) => {
            if (!containerRef.current?.parentElement || !headerRef.current || !width || !height) {
                return;
            }

            const totalHeightNeeded = (startPointY || 0) + height;
            const totalWidthNeeded = (startPointX || 0) + width;
            const dialogContainerHeight = containerRef.current.parentElement.clientHeight;
            const dialogContainerWidth = containerRef.current.parentElement.clientWidth;

            const totalHeightIsMoreThanAvailableHeight = totalHeightNeeded > dialogContainerHeight;
            const totalWidthIsMoreThanAvailableWidth = totalWidthNeeded > dialogContainerWidth;

            const headingHeight = headerRef.current.clientHeight;
            if (totalHeightIsMoreThanAvailableHeight) {
                let topOffset = dialogContainerHeight - height - headingHeight;
                let dialogHeight: number | undefined;

                if (topOffset < MINIMUM_TOP_OFFSET) {
                    topOffset = MINIMUM_TOP_OFFSET;
                    dialogHeight = dialogContainerHeight - MAP_PADDING_VERTICAL - headingHeight;
                }

                setHeight(dialogHeight);
                setTopOffset(topOffset);
            } else {
                setHeight(undefined);
                setTopOffset(undefined);
            }

            if (totalWidthIsMoreThanAvailableWidth) {
                const leftOffset = dialogContainerWidth - width - MAP_PADDING_HORIZONTAL;

                setLeftOffset(leftOffset);
            } else {
                setLeftOffset(undefined);
            }
        },
        [startPointX, startPointY],
    );
    useResizeDetector({
        targetRef: dialogContentRef,
        onResize: onResize,
        refreshMode: 'throttle',
        refreshRate: 1_000
    });

    const [mouseDown, setMouseDown] = useState(false);

    return (
        <Draggable handle="h3" bounds="parent" nodeRef={containerRef}>
            <div
                ref={containerRef}
                className={css(
                    styles.dialogContainer,
                    themeContext.currentColorSchemeIsDark && darkStyles.dialogContainer,
                    themeContext.currentColorSchemeIsSuitableForColorBlindness && colorBlindnessStyles.dialogContainer,
                )}
                style={{
                    left: `${leftOffset || startPointX || '10'}px`,
                    position: 'absolute',
                    top: `${topOffset || startPointY || '10'}px`,
                    zIndex: zIndex
                }}
            >
                <h3
                    ref={headerRef}
                    className={css(styles.dialogHeader, mouseDown && styles.dialogContainerMouseDown)}
                    onMouseDown={() => {
                        focusOnDialog();
                        setMouseDown(true);
                    }}
                    onMouseUp={() => setMouseDown(false)}
                    onMouseLeave={() => setMouseDown(false)}
                >
                    <div className={css(styles.dialogHeaderTitle)}>
                        <span>{title}</span>
                    </div>
                    <div className={css(styles.dialogHeaderButtons)}>
                        <div
                            className={css(styles.dialogHeaderPin, pinned && styles.dialogHeaderPinActive)}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                requestPinStatusChange();
                            }}
                            title={pinned ? 'Maak deze popup los' : 'Maak deze popup vast'}
                        >
                            <i className="fas fa-thumbtack" />
                        </div>
                        <div
                            className={css(styles.dialogHeaderClose)}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                requestDialogClose();
                            }}
                            title={`Sluit popup ${title}`}
                        >
                            <i className="fas fa-window-close" />
                        </div>
                    </div>
                </h3>

                <div
                    className={css(styles.dialogContent)}
                    style={{
                        height: height ? `${height}px` : 'initial'
                    }}
                >
                    <div ref={dialogContentRef}>{children}</div>
                </div>
            </div>
        </Draggable>
    );
};

export default DraggableDialog;
