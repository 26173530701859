import React, {useMemo} from 'react';
import {css} from 'aphrodite/no-important';
import styles from './styles';
import {Props} from './Props';

const StyledButton: React.FunctionComponent<Props> = ({
    disabled,
    title,
    selected,
    onPress,
    styles: extraStyles,
    ...props
}) => {
    const cssStyle = useMemo(
        () =>
            selected
                ? css(styles.button, ...(extraStyles || []), styles.selected)
                : css(styles.button, ...(extraStyles || [])),
        [selected, extraStyles],
    );

    return (
        <button
            type="button"
            onClick={onPress}
            className={cssStyle}
            disabled={disabled}
            {...props}
        >
            {title}
        </button>
    );
};

export default StyledButton;
