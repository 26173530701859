import {combineReducers} from 'redux';
import mapSceneReducer, {MapSceneCombinedReducerState} from './scenes/MapScene/reducer';
import userSettingsReducer, {UserSettingsReducerState} from './modules/UserSettingsReducer/reducer';
import {keycloakAuthenticationReducer, KeycloakAuthenticationState} from '@ndw/react-keycloak-authentication';
import trafficCenterReducer, {TrafficCenterState} from './modules/TrafficCenterReducer/reducer';
import notificationModuleReducer, {NotificationReducerState} from './modules/NotificationReducer/reducer';

export interface RootState {
    keycloakAuthentication: KeycloakAuthenticationState;
    mapScene: MapSceneCombinedReducerState;
    notification: NotificationReducerState;
    trafficCenter: TrafficCenterState;
    userSettings: UserSettingsReducerState;
}

const rootReducer = combineReducers<RootState>({
    keycloakAuthentication: keycloakAuthenticationReducer,
    mapScene: mapSceneReducer,
    notification: notificationModuleReducer,
    trafficCenter: trafficCenterReducer,
    userSettings: userSettingsReducer
});

export default rootReducer;
