import React, {ReactElement} from 'react';
import {MapFilters} from '../../../../scenes/MapScene/reducers/filterAndSearchReducer';
import {translateFrontOfficeEventStatus} from '../../../../services/Utilities';
import AgGrid from '../../../AgGrid';
import {TimeCellRenderer} from '../../../AgGrid/components/DateCellRenderer';
import {MessageAgeCellRenderer} from '../../../AgGrid/components/MessageAgeCellRenderer';
import {FilterSettings} from '../../../AgGrid/Props';
import {Props} from './Props';
import {State} from './State';
import {WazeFeature} from '../../../../interfaces/WazeFeature';
import {handleRowClick} from '../TableService';
import {ICellRendererParams} from '@ag-grid-community/core';

class OverviewAllWazeAlertOther extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            filters: null
        };
    }

    componentDidMount(): void {
        this.updateGridFilters(this.props.mapFilter);
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const {mapFilter} = this.props;

        if (mapFilter.filterTimeStamp !== prevProps.mapFilter.filterTimeStamp) {
            this.updateGridFilters(mapFilter);
        }
    }

    render(): ReactElement {
        const {data, showRecordIds, suppressRowClickSelection} = this.props;
        const {filters} = this.state;

        return <AgGrid<WazeFeature>
            columns={[
                {
                    cellRenderer: (columnData: ICellRendererParams) => {
                        return <img src={`/assets/legend-icons/ndw-${columnData.value}-25.svg`} alt="Icoon"/>;
                    },
                    valueGetter: item => item.data.properties.icon,
                    headerName: '',
                    maxWidth: 50,
                    resizable: false
                },
                {
                    valueGetter: item => item.data.properties.id,
                    headerName: 'ID',
                    hide: !showRecordIds
                },
                {
                    cellRenderer: (columnData: ICellRendererParams) => translateFrontOfficeEventStatus(columnData.value),
                    valueGetter: item => item.data.properties.status,
                    headerName: 'Status'
                },
                {
                    cellRenderer: TimeCellRenderer,
                    valueGetter: item => item.data.properties.publicationTime,
                    headerName: 'Starttijd',
                    minWidth: 130,
                    sort: 'desc'
                },
                {
                    cellRenderer: MessageAgeCellRenderer,
                    valueGetter: item => item.data.properties.publicationTime,
                    headerName: 'Duur incident'
                },
                {
                    headerName: 'Locatie',
                    valueGetter: (columnData) => {
                        if (
                            columnData.data.properties.hectometrePost
                            && (!isNaN(columnData.data.properties.hectometrePost) || columnData.data.properties.hectometrePost.length)
                        ) {
                            const hectometrePost = columnData.data.properties.hectometrePost;
                            return `${columnData.data.properties.street}, ${hectometrePost}`;
                        }
                        return columnData.data.properties.street;
                    }
                },
                {
                    valueGetter: item => item.data.properties.thumbsUp,
                    headerName: 'Bevestigingen',
                    maxWidth: 130
                },
                {
                    cellRenderer: MessageAgeCellRenderer,
                    valueGetter: item => item.data.properties.lastConfirmedTime,
                    headerName: 'Laatste bevestiging'
                },
                {
                    valueGetter: item => item.data.properties.reliability,
                    headerName: 'Betrouwbaarheid',
                    maxWidth: 130
                },
                {
                    valueGetter: item => item.data.properties.confidence,
                    headerName: 'Feedback score',
                    maxWidth: 130
                },
                {
                    cellRenderer: (columnData: ICellRendererParams) => {
                        const active = columnData.value === true;
                        return <i className={`fas ${active ? 'fa-check' : 'fa-times'}`} style={{color: active ? '#28a745' : '#dc3545'}} />;
                    },
                    valueGetter: item => item.data.properties.ndw,
                    headerName: 'NDW Bekend'
                }
            ]}
            data={data}
            enableColResize={true}
            enableFilter={true}
            enableSorting={true}
            filters={filters ? filters : undefined}
            rowSelection={'single'}
            suppressScrollOnNewData={true}
            onRowClicked={(item) => handleRowClick(this.props, item)}
            autoRedrawTableColumns={true}
            suppressRowClickSelection={suppressRowClickSelection}
        />;
    }

    private updateGridFilters = (mapFilters: MapFilters) => {
        const filters: FilterSettings<WazeFeature> = {
            filters: [],
            identifier: mapFilters.filterTimeStamp
        };

        if (mapFilters.wazeAlertStatusSet) {
            filters.filters.push({
                field: 'properties.status' as keyof WazeFeature,
                value: ['NONE']
            });
        }
        if (mapFilters.wazeAlertNdwKnown) {
            filters.filters.push({
                field: 'properties.ndw' as keyof WazeFeature,
                value: ['false']
            });
        }

        this.setState({
            filters
        });
    };

}

export default OverviewAllWazeAlertOther;
