import {css} from 'aphrodite';
import _ from 'lodash';
import React, {MouseEvent, ReactElement} from 'react';
import {GeoJsonPropertiesWaze} from '../../../../../../interfaces/GeoJsonSources/GeoJsonPropertiesWaze';
import {MapFeature} from '../../../../../../interfaces/MapFeature';
import {NAV_BAR_HEIGHT} from '../../../../../../styles';
import {Props} from './Props';
import styles from './styles';
import {GeoJsonPropertiesRoadWorkPlannedOtm} from '../../../../../../interfaces/GeoJsonSources/GeoJsonPropertiesRoadWorkPlannedOtm';
import {GeoJsonPropertiesFd} from '../../../../../../interfaces/GeoJsonSources/GeoJsonPropertiesFd';
import {GeoJsonPropertiesSchoolzone} from '../../../../../../interfaces/GeoJsonSources/GeoJsonPropertiesSchoolzone';

import {
    GeoJsonPropertiesSituation
} from '../../../../../../interfaces/GeoJsonSources/GeoJsonPropertiesSituation';

class DialogSelectFeature extends React.Component<Props> {

    clickOnFeature = (event: MouseEvent<HTMLLIElement>, feature: MapFeature): void => {
        this.props.clickedFeatures([feature], event.clientX, event.clientY - NAV_BAR_HEIGHT);
    };

    render(): ReactElement {
        const {features} = this.props;

        const result = _.chain(features)
            .groupBy('layer')
            .value();
        const layers = Object.keys(result);

        return <>
            {layers.map((item: string, index: number) => this.renderMapLayers(item, result[item], index))}
        </>;
    }

    private renderMapLayers = (mapLayerID: string, features: MapFeature[], index: number) => {
        const {mapLayers} = this.props;

        const layerName = mapLayers.hasOwnProperty(mapLayerID) ? mapLayers[mapLayerID].name : mapLayerID;
        const layerDescription = mapLayers.hasOwnProperty(mapLayerID) ? mapLayers[mapLayerID].description : mapLayerID;

        return <React.Fragment key={index}>
            <h2 className={css(styles.header)}>{layerName} {(layerDescription && layerDescription.length > 0) && <small>({layerDescription})</small>}</h2>
            <ul>
                {features.map((feature: MapFeature) => this.renderFeature(feature))}
            </ul>
        </React.Fragment>;
    };

    private renderFeature = (feature: MapFeature) => {
        return <li
            key={feature.id}
            className={css(styles.featureItem)}
            onClick={(event: MouseEvent<HTMLLIElement>) => this.clickOnFeature(event, feature)}
        >
            <i className="fas fa-arrow-right"/> <span>{this.getNameForFeature(feature)}</span>
        </li>;
    };

    private getNameForFeature = (feature: MapFeature) => {
        const {mapLayers} = this.props;

        switch (feature.layer) {
            case 'onda':
                const featurePropertiesOnda = feature.featurePropertiesData as unknown as GeoJsonPropertiesSituation;
                return `${featurePropertiesOnda.locationDescription}`;
            case 'wazeAlerts':
            case 'wazeAlertOther':
            case 'wazeJam':
            case 'wazeIrregularity':
                const layerName = mapLayers[feature.layer].name;
                const featureProperties = feature.featurePropertiesData as unknown as GeoJsonPropertiesWaze;

                if (featureProperties.street || featureProperties.city) {
                    return `${layerName} op ${featureProperties.street} ${featureProperties.city}`;
                }

                return `${layerName} ${feature.id}`;
            case 'trafficMessageOtis':
            case 'trafficMessageFlisvis':
                const featurePropertiesTrafficMessage = feature.featurePropertiesData as unknown as GeoJsonPropertiesSituation;
                return `${featurePropertiesTrafficMessage.locationDescription}`;
            case 'travelTimeFcd':
            case 'travelTimeOther':
            case 'travelTimeDrips':
                try {
                    const parsed = JSON.parse(feature.featurePropertiesData.locations as string);
                    return parsed[0];
                } catch (e) {
                    return feature.id;
                }
            case 'roadWorkPlannedOtm':
                const propertiesRoadWorkOtm = feature.featurePropertiesData as unknown as GeoJsonPropertiesRoadWorkPlannedOtm;
                return `Geplande wegwerkzaamheden (OTM) - ${propertiesRoadWorkOtm.id}`;
            case 'fd':
                const propertiesFd = feature.featurePropertiesData as unknown as GeoJsonPropertiesFd;
                return `Ongevalideerde files ${propertiesFd.headLocation} tot ${propertiesFd.tailLocation}`;
            case 'schoolzones':
                const propertiesSchoolzone = feature.featurePropertiesData as unknown as GeoJsonPropertiesSchoolzone;
                return propertiesSchoolzone.name;
            default:
                return feature.id;
        }
    };

}

export default DialogSelectFeature;
