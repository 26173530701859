import React, {ReactElement} from 'react';
import {Props} from './Props';
import DateTimeFormatted from '../../../../../../components/DateTimeFormatted';
import StyledBoolean from '../../../../../../components/StyledBoolean';
import BearingCompass from '../../../../../../components/BearingCompass';

class DialogMSI extends React.Component<Props> {

    render(): ReactElement {
        const {currentProperties, showRecordIds} = this.props;

        return <table>
            <tbody>
                {showRecordIds && <tr>
                    <th>ID</th>
                    <td>{currentProperties.id}</td>
                </tr>}
                <tr>
                    <th>Weg</th>
                    <td>
                        {currentProperties.road}{currentProperties.carriageway} thv {currentProperties.km}km
                        rijstrook {currentProperties.lane}
                    </td>
                </tr>
                <tr>
                    <th>Richting</th>
                    <td>
                        <BearingCompass
                            bearing={currentProperties.bearing}
                        />
                    </td>
                </tr>
                <tr>
                    <th>Weergave</th>
                    <td>{currentProperties.display}</td>
                </tr>
                <tr>
                    <th>Knippert</th>
                    <td>
                        <StyledBoolean value={currentProperties.flashing}/>
                    </td>
                </tr>
                <tr>
                    <th>Rode rand</th>
                    <td>
                        <StyledBoolean value={currentProperties.redRing}/>
                    </td>
                </tr>
                <tr>
                    <th>Links invoegen</th>
                    <td>
                        <StyledBoolean value={currentProperties.mergeLeft}/>
                    </td>
                </tr>
                <tr>
                    <th>Rechts invoegen</th>
                    <td>
                        <StyledBoolean value={currentProperties.mergeRight}/>
                    </td>
                </tr>
                <tr>
                    <th>Event tijd</th>
                    <td>
                        <DateTimeFormatted
                            dateTime={currentProperties.tsEvent}
                        />
                    </td>
                </tr>
                <tr>
                    <th>State tijd</th>
                    <td>
                        <DateTimeFormatted
                            dateTime={currentProperties.tsState}
                        />
                    </td>
                </tr>
            </tbody>
        </table>;
    }

}

export default DialogMSI;
